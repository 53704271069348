import {Injectable} from "@angular/core";
import {forkJoin, Observable, of, switchMap} from "rxjs";
import {UserService} from "./user.service";
import {AccessService} from "./access.service";
import {AccessType} from "../models/access.type";
import {map, take, withLatestFrom} from "rxjs/operators";
import {UrlTree} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

export class MenuItem {
  constructor(
    public type: AccessType,
    public label: string,
    public routerLink: string,
    public icon: string,
    public badge?: Observable<number>,
    public available: boolean = false
  ) {}

  setAvailable(): MenuItem {
    this.available = true
    return this;
  }

  setUnavailable(): MenuItem {
    this.available = false;
    return this;
  }
}

@Injectable({
  providedIn: "root"
})
export class AccessMenuService {

  private _items: MenuItem[] = [
    new MenuItem(AccessType.dashboard, this.t.instant('Home'), '/dashboard', 'pq:home'),
    new MenuItem(AccessType.payments, this.t.instant('Payments'), '/payment', 'pq:credit_card_hand_cursor'),
    new MenuItem(AccessType.maintenanceRequests, this.t.instant('Maintenance'), '/maintenance', 'pq:screwdriver_ruler', this.userService.unreadMr),
    new MenuItem(AccessType.communications, this.t.instant('Messages'), '/message', 'pq:email_letter', this.userService.unread),
    new MenuItem(AccessType.amenities, this.t.instant('Amenities'), '/amenity', 'pq:hand_key'),
    new MenuItem(AccessType.parcels, this.t.instant('Parcel'), '/parcel', 'pq:parcel'),
    new MenuItem(AccessType.promotions, this.t.instant('Promotions'), '/promotion', 'pq:discount'),
    new MenuItem(AccessType.insurance, this.t.instant('Insurance'), '/insurance', 'pq:protected'),
    new MenuItem(AccessType.documents, this.t.instant('Documents'), '/document', 'pq:documents_file_text'),
    new MenuItem(AccessType.upcomingEvents, this.t.instant('Upcoming Events'), '/upcoming-event', 'pq:calendar'),
  ];

  items: Observable<MenuItem[]> = forkJoin(
    this._items.map(i => this.accessService.can(i.type)
      .pipe(
        withLatestFrom(of(i)),
        take(1),
        switchMap(([available, item]: [boolean|UrlTree, MenuItem]) => {
          return of(
            typeof available === 'boolean' && available
              ? item.setAvailable()
              : item.setUnavailable()
          );
        })
      )
    )
  ).pipe(
    map((m:MenuItem[]) => m.filter(i => i.available))
  )

  itemsMain: Observable<MenuItem[]> = this.items.pipe(
    map(
      items => items
        .filter(i => i.type !== AccessType.communications)
        .slice(0, 3)
    )
  )

  itemsSideNav: Observable<MenuItem[]> = this.items.pipe(
    map(
      items => items
        .filter(i => i.type !== AccessType.communications)
        .slice(3)
    )
  )

  itemMessage: Observable<MenuItem|undefined> = this.items.pipe(
    map(
      items => items
        .filter(i => i.type === AccessType.communications)
        .shift()
    )
  )

  constructor(
    private userService: UserService,
    private accessService: AccessService,
    private t: TranslateService
  ) {
  }
}