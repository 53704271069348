import {Inject, Injectable} from "@angular/core";
import { HttpEvent, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from "@angular/common/http";
import {Observable, ObservableInput} from "rxjs";
import {AuthService} from "./services/auth.service";
import {catchError} from "rxjs/operators";
import {API_BASE_URL, API_VERSION} from "./shared.module";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    @Inject(API_BASE_URL) private baseUrl: string,
    @Inject(API_VERSION) private version: string
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (!req.url.startsWith(this.baseUrl)) {
      return next.handle(req);
    }

    const idToken         = this.authService.getToken().getValue();
    const csrfToken       = this.authService.getCsrfToken().getValue();
    const host            = window.location.host;
    const subdomain       = environment.appId === 'com.medallion.app' ? 'medallion' : host.split('.')[0];

    let reqHeaders = req.headers.set("TenantSubdomain", subdomain)

    if(idToken) {
      reqHeaders = reqHeaders.set("Authorization",  "Bearer " + idToken)
    }

    if(csrfToken) {
      reqHeaders = reqHeaders.set("X-CSRF-TOKEN", csrfToken)
    }

    const cloned = req.clone({
      headers: reqHeaders,
      withCredentials: true,
    });

    return next.handle(cloned).pipe(
      catchError(this.catchError.bind(this))
    );
  }

  catchError(
    err: any,
    event: Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>>
  ): ObservableInput<any> {
    if (err.status === 401) {
      this.authService.setToken(null);
      // this.router.navigate(['/login']);
    }
    if (err.status === 403 && err.url.endsWith('/users/me/')) {
      this.authService.setToken(null);
      // window.location.href = '/login';
      // this.router.navigate(['/login']);
    }
    throw err;
  }
}
