import { Action, createReducer, on } from "@ngrx/store";
import { AppState, initialAppState } from "./app.state";
import * as actions from "./app.actions";

const _reducer = createReducer(
  initialAppState,
  on(actions.setPaymentSessionKey, (state, { paymentSessionKey }) => ({
    ...state,
    paymentSessionKey,
  }))
);

export function appReducer(state: AppState | undefined, action: Action) {
  // console.log('appReducer action state', action, state);
  return _reducer(state, action);
}
