import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";
import {UserService} from "../../shared/services/user.service";
import {AuthService} from "../../shared/services/auth.service";
import {Router} from "@angular/router";
import {CustomisationService} from "../../shared/services/customisation.service";
import {combineLatest, of, switchMap} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmRedirectComponent} from "./confirm-redirect/confirm-redirect.component";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-sidenav-profile',
  templateUrl: './sidenav-profile.component.html',
  styleUrls: ['./sidenav-profile.component.scss']
})
export class SidenavProfileComponent implements OnInit {

  @HostBinding('class.sidenav-container') sidenavContainerClass = true;
  @HostBinding('class.account-sidebar')   accountSidebarClass   = true;

  @Input() matSidenav?: MatSidenav;

  user = this.userService.me();
  accounts = this.user.pipe(switchMap(u => of(u.linked_accounts)));
  currentAccount = combineLatest([
    this.user,
    this.accounts
  ]).pipe(
    switchMap(([u, a]) => of(a?.filter(i => i.id === u.id).pop()))
  );
  avatar = this.userService.avatarUrl;

  constructor(
    public customization: CustomisationService,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  signOut() {
    this.authService.logout();
  }

  switchUser(id: number) {
    console.log('SWITCH USER', id);
    this.authService.switch(id);
  }


  goLeasingPortal() {
    this.userService.me().pipe(take(1)).subscribe(user => {
      const subdomain = user?.property?.company?.subdomain ?? '';
      const url = `https://${subdomain}.leasing.payquad.com/#/n/login`;

      const dialogRef = this.dialog.open(ConfirmRedirectComponent, {
        width: '600px',
        data: { url: url },
      });
    });
  }
}
