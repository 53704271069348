import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {MobileService} from "../../shared/services/mobile.service";

@Component({
  selector: 'app-message-layout',
  templateUrl: './message-layout.component.html',
  styleUrls: ['./message-layout.component.scss']
})
export class MessageLayoutComponent implements OnInit {
  layout: Observable<{toolbar: boolean, footer: boolean}> = new BehaviorSubject({toolbar: true, footer: true});

  constructor(
    public mobileService: MobileService
  ) { }

  ngOnInit(): void {
  }

}
