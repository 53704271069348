import {Component, OnInit} from '@angular/core';
import {CustomisationService} from "../../shared/services/customisation.service";
import {BehaviorSubject, Observable} from "rxjs";

@Component({
  selector: 'app-view-layout',
  templateUrl: './view-layout.component.html',
  styleUrls: ['./view-layout.component.scss']
})
export class ViewLayoutComponent implements OnInit {

  layout: Observable<{toolbar: boolean, footer: boolean}> = new BehaviorSubject({toolbar: true, footer: true});

  constructor(
    public customization: CustomisationService
  ) { }

  ngOnInit(): void {
  }

}
