import {ThemePalette} from "@angular/material/core";

export class ConfirmationWindowDataModel {
  constructor(
    public title: string,
    public message: string,
    public acceptButtonText: string,
    public acceptButtonColor: ThemePalette,
    public rejectButtonText: string,
    public rejectButtonColor: ThemePalette,
  ) {
  }
}