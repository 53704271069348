<div class="one-row one-row-space-around title-row">
    <span mat-dialog-title>{{dialogData.title}}</span>
    <button
            mat-button
            mat-dialog-close
            class="mat-dialog-close mat-dialog-icon-close"
    >
        <mat-icon svgIcon="pq:close"></mat-icon>
    </button>
</div>

<mat-dialog-content class="dialog-content">
    <p class="body2">{{dialogData.message}}</p>
    <mat-dialog-actions class="one-row one-row-2col-same-size">
        <button [color]="dialogData.rejectButtonColor" mat-stroked-button (click)="matDialogRef.close(false)">{{dialogData.rejectButtonText}}</button>
        <button [color]="dialogData.acceptButtonColor" mat-flat-button (click)="matDialogRef.close(true)">{{dialogData.acceptButtonText}}</button>
    </mat-dialog-actions>
</mat-dialog-content>