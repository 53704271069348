import {BaseRestService} from "../../shared/rest/base-rest.service";
import {DashboardMessageModel} from "./resources/dashboard-message.model";
import { HttpClient } from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {API_BASE_URL, API_VERSION} from "../../shared/shared.module";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class DashboardMessageService extends BaseRestService<
  DashboardMessageModel,
  DashboardMessageModel,
  DashboardMessageModel,
  DashboardMessageModel,
  DashboardMessageModel
  >{
  resourceName = 'dashboard-message';

  storage: 'sessionStorage' | 'localStorage' = 'sessionStorage';
  storageKey = 'dashboardMessageRead'
  readMessageIds = new BehaviorSubject<number[]>(this.getReadMessages());

  markAsRead(m: DashboardMessageModel) {
    const current = this.getReadMessages();
    if(m.id) {
      current.push(m.id);
    }
    this.setReadMessages(current);
  }

  clearRead() {
    this.setReadMessages([]);
  }

  private getReadMessages() {
    let current: Array<number> = [];
    try {
      current = JSON.parse(window[this.storage].getItem(this.storageKey) || '[]');
    } catch (e) {
      window[this.storage].setItem(this.storageKey, JSON.stringify([]));
    }
    return current;
  }

  private setReadMessages(messages: Array<number>) {
    window[this.storage].setItem(this.storageKey, JSON.stringify([...new Set(messages)]));
    this.readMessageIds.next(this.getReadMessages());
  }

  constructor(
    http: HttpClient,
    @Inject(API_BASE_URL) baseUrl: string,
    @Inject(API_VERSION) version: string
  ) {
    super(http, baseUrl, version);
  }
}