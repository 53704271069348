// https://github.com/armanozak/angular-dynamic-locale

import { LocalizationService } from './shared/services/localization.service';

export class LocaleId extends String {
  constructor(private localeService: LocalizationService) {
    super();
  }

  toString(): string {
    return this.localeService.currentLang;
  }

  valueOf(): string {
    return this.toString();
  }
}