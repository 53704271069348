import { Component } from '@angular/core';
import {UserService} from "../services/user.service";

@Component({
  selector: 'app-login-as-notice',
  templateUrl: './login-as-notice.component.html',
  styleUrls: ['./login-as-notice.component.scss']
})
export class LoginAsNoticeComponent {

  public manager_id = 0;
  public manager_name = '';
  constructor(
      private userService: UserService
  ) { }

  ngOnInit(): void {
    this.userService.user.subscribe(r => {
        this.manager_name = r?.login_as?.first_name + ' ' + r?.login_as?.last_name;
        this.manager_id = Number(r?.login_as?.id);
    })
  }

}
