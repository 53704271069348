<mat-card   class="form-mat-card">
    <form [formGroup]="formGroup" class="full-width-field">
        <span class="h5">{{'Find Your Property' | translate}}</span>
        <div class="postal-code-container">
            <h5 class="row postal-label">
                {{"Enter your postal code" | translate}}
            </h5>
            <mat-form-field class="row">
                <input
                    matInput
                    class="full-width-field"
                    formControlName="postal_code"
                    [placeholder]="'Postal Code' | translate"
                />
                <mat-error>
                    <span *ngIf="formGroup.controls['postal_code']?.errors?.invalidPostalCode">{{'Invalid Postal Code' | translate}}</span>
                    <span *ngIf="formGroup.controls['postal_code']?.errors?.noProperties">{{'No properties found' | translate}}</span>
                </mat-error>
            </mat-form-field>
            <app-ellipses-loader *ngIf="(activeSearch | async)"></app-ellipses-loader>
        </div>
        <div class="pq-form full-column" *ngIf="(showPropertyDropdown | async) && this.formGroup.controls.postal_code.value.replace('[^a-zA-Z0-9]/g', '').length >= 5">
            <h5>
                {{'Select Your Property' | translate}}
            </h5>

            <ng-select
                    id="property-dropdown"
                    class="full-width-field"
                    bindValue="id"
                    bindLabel="address"
                    formControlName="property_id"
                    [items]="propertyData | async"
                    [placeholder]="'Find your property' | translate"
                    [typeahead]="propertySearchSubject"
                    (scrollToEnd)="loadMoreProperties()"
                    (change)="onPropertyChange($event)"
            ></ng-select>
        </div>

        <span class="property-container" *ngIf="(property | async) as prop">
            <mat-icon svgIcon="pq:skyscraper_modern_1" class="property-icon"></mat-icon>
            <span class="property-container__label body2 accent-font-2">
                <span class="property-name">{{prop.name}}</span>
                <span class="property-address">{{prop.address}}</span>
            </span>
            <div class="property-container__input">
                <span class="body3 accent-font-2">{{'Unit' | translate}}</span>
                <br>
                <ng-select
                        class="full-width-field mat-form-field mat-form-field-size-small mat-form-field-no-wrap unit-dropdown"
                        bindValue="unit"
                        bindLabel="unit"
                        formControlName="unit"
                        [items]="unitData | async"
                        [placeholder]="'Find your unit' | translate"
                        [typeahead]="unitSearchSubject"
                        (scrollToEnd)="loadMoreUnits()"
                        (change)="unitChanged($event)"
                ></ng-select>
            </div>
        </span>
        <button
                mat-flat-button
                color="primary"
                class="send-button full-column"
                [disabled]="(formGroup.invalid) || (sendActiveRequest|async)"
                (click)="onSubmit()"
        >
            <mat-spinner [diameter]="20"
                         *ngIf="(sendActiveRequest|async)"
            ></mat-spinner>
            <span>{{'Continue'}}</span>
            <mat-icon svgIcon="pq:arrow_right"></mat-icon>
        </button>
        <div class="notice-container">
            {{'Already have an account?' | translate}} <a class="notice-link" routerLink="/login"> {{'Login' | translate}}</a>
            <br/>
            <a class="notice-link" (click)="openPropertyNotFoundDialog()">{{'I cant find my property' | translate}}</a>
        </div>
    </form>
</mat-card>

<app-download-app></app-download-app>
