import {Inject, Injectable} from '@angular/core';
import {BaseRestService} from "../../shared/rest/base-rest.service";
import {UpcomingEventModel} from "./models/upcoming-event.model";
import { HttpClient } from "@angular/common/http";
import {API_BASE_URL, API_VERSION} from "../../shared/shared.module";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UpcomingEventService extends BaseRestService<
  UpcomingEventModel,
  UpcomingEventModel,
  UpcomingEventModel,
  UpcomingEventModel,
  UpcomingEventModel
  >{

  protected resourceName = 'social-event';

  constructor(
    http: HttpClient,
    @Inject(API_BASE_URL) baseUrl: string,
    @Inject(API_VERSION) version: string
  ) {
    super(http, baseUrl, version);
  }

  rsvp(id: number, attendees?: number) {
    // /api/v2/social-event/3/rsvp
    return this.http.post(
      `${this.baseUrl}${this.version}/${this.resourceName}/${id}/rsvp`,
      {attendees},
      {observe: "response"}
    ).pipe(
      tap((r) => this.dataChanged.emit()),
    );
  }

  rsvpCancel(id: number) {
    return this.http.delete(
      `${this.baseUrl}${this.version}/${this.resourceName}/${id}/rsvp`,
      {observe: "response"}
    ).pipe(
      tap((r) => r.status === 204 ? this.dataChanged.emit() : null),
    );
  }

}
