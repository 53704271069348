import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {distinctUntilChanged, switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  cssMaxWidth = new BehaviorSubject<number>(window.innerWidth);
  mobileView: Observable<boolean> = this.cssMaxWidth.pipe(switchMap(d => [d <= 840]), distinctUntilChanged())

  sidenavTopGap = this.cssMaxWidth.pipe(
    switchMap(w => of(w <= 840 ? 56 : 86))
  );
  sidenavBottomGap = this.cssMaxWidth.pipe(
    switchMap(w => of(w > 1024 ? 0 : (w < 640 ? 65 : 45)))
  );

  constructor() { }
}
