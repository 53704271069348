<app-toolbar
        [sidenavAdditionalServices]="sidenavAdditionalServices"
        [sidenavProfile]="sidenavProfile"
        *ngIf="(layout | async)?.toolbar"
></app-toolbar>
<mat-sidenav-container>
    <mat-sidenav #sidenavAdditionalServices
                 role="navigation"
                 [fixedInViewport]="true"
                 [fixedTopGap]="mobileService.sidenavTopGap|async"
                 [fixedBottomGap]="mobileService.sidenavBottomGap|async"
    >
        <app-sidenav-additional-services [matSidenav]="sidenavAdditionalServices"></app-sidenav-additional-services>
    </mat-sidenav>
    <mat-sidenav #sidenavProfile position="end" role="navigation" class="sidenav-container-profile">
        <app-sidenav-profile [matSidenav]="sidenavProfile"></app-sidenav-profile>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
        <main class="main-container">
            <div class="router-container">
                <router-outlet></router-outlet>
            </div>
        </main>
        <app-footer *ngIf="(layout | async)?.footer"></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
