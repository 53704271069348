import {Inject, Injectable} from "@angular/core";
import {BaseRestService} from "../../shared/rest/base-rest.service";
import {InviteMetaModel} from "./invite-meta.model";
import { HttpClient } from "@angular/common/http";
import {API_BASE_URL, API_VERSION} from "../../shared/shared.module";

@Injectable({
  providedIn: "root"
})
export class EmailInviteService extends BaseRestService<InviteMetaModel, InviteMetaModel, InviteMetaModel, InviteMetaModel, InviteMetaModel> {
  protected resourceName = 'invite-code';

  constructor(
    http: HttpClient,
    @Inject(API_BASE_URL) baseUrl: string,
    @Inject(API_VERSION) version: string
  ) {
    super(http, baseUrl, version);
  }
}