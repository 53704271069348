import { inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { RegistrationService } from "../../login/registration.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class PropertySelectedGuard {
  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private notif: ToastrService,
    private translate: TranslateService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    if (this.registrationService.registrationFormData.getValue().property_id) {
      return of(true);
    } else {
      this.notif.error(this.translate.instant("No Property Selected"));
      return of(this.router.createUrlTree(["/property"]));
    }
  }
}

export const PropertySelected: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(PropertySelectedGuard).canActivate();
};
