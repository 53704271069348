import {Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app'
@Component({
  selector: 'app-app-version-update-prompt',
  templateUrl: './app-version-update-prompt.component.html',
  styleUrls: ['./app-version-update-prompt.component.scss']
})
export class AppVersionUpdatePromptComponent implements OnInit {
  public displayUpdatePrompt = true
  public platform = Capacitor.getPlatform()
  private appId: null | string = null
  @ViewChild(TemplateRef) ref: any;

  private storeLinkInfo: any = {
    ios: {
        link: 'https://apps.apple.com/app/',
        ids: {
            'com.payquad': 'id1502174216',
            'com.payquad.manager': 'id1551011738',
            'com.medallion.app': 'id1555362357'
        }

    },
     android: {
        link: 'https://play.google.com/store/apps/details?id=',
        ids: {
            'com.payquad': 'com.payquad',
            'com.payquad.manager': 'com.payquad.manager',
            'com.medallion.app': 'com.medallion.app'
        }
     }
}

  constructor(
    public matDialogRef: MatDialogRef<AppVersionUpdatePromptComponent>,
    public dialog: MatDialog,
    ) {
      App.getInfo().then(res => this.appId = res.id)
    }

  ngOnInit(): void {
  }

  openStoreLink() {
    if(!this.appId) return
    const objInfo = this.storeLinkInfo[this.platform]
    const storeLink = `${objInfo.link}${objInfo.ids[this.appId]}`;
    window.open(storeLink)
  }
}
