import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {InviteMetaModel} from "./invite-meta.model";
import {combineLatest, Observable, of} from "rxjs";
import {RegistrationService} from "../registration.service";

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  meta: Observable<InviteMetaModel> = combineLatest([
    this.activedRoute.queryParamMap,
    this.activedRoute.data
  ]).pipe(
    switchMap(([params, data]) => {
      console.log('inviteMeta', data.inviteMeta);
      return of(
        Object.assign(new InviteMetaModel(params.get('invite-code') as string), data['inviteMeta'])
      )
    })
  );

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
  ) {}

  ngOnInit(): void {
  }

  continue(code: string, first_name: string, last_name: string, email: string) {
    this.meta.subscribe({
      next: (meta) => {
        this.registrationService.setRegistrationFormData({
          invite_code: code, 
          first_name: first_name, 
          last_name: last_name, 
          email: email,
          property_id: meta.property?.id,
          property: meta.property,
          unit: meta.unit?.unit,
        });
      }
    })
    
    this.router.navigate(['/onboarding']);
  }

}
