import {AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit} from '@angular/core';
import {RegistrationService} from "../registration.service";
import {PropertyModel} from "../../shared/models/property.model";
import {UnitModel} from "../resources/unit.model";
import {BehaviorSubject, Observable, Subject, Subscription, combineLatest} from "rxjs";
import {PropertyService} from "../resources/property.service";
import {UnitService} from "../resources/unit.service";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {DataSourceService} from "../../shared/rest/data-source.service";
import {debounceTime, map, tap, withLatestFrom, filter, distinctUntilChanged, switchMap, shareReplay} from "rxjs/operators";
import {AuthService} from "../../shared/services/auth.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../shared/services/user.service";
import {MatDialog} from '@angular/material/dialog';
import {PropertyNotFoundDialog} from './property-not-found/property-not-found.component';
import {  PqValidators } from "../../shared/validators/pq.validators";

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss'],
  providers: [
    PropertyService,
    UnitService,
    {
      provide: 'propertyDataSource',
      useClass: DataSourceService,
      deps: [PropertyService],
    },
    {
      provide: 'unitsDataSource',
      useClass: DataSourceService,
      deps: [UnitService],
    },

  ]
})
export class PropertyComponent implements OnInit, AfterViewInit, OnDestroy {

  public formGroup: UntypedFormGroup;

  public showPropertyDropdown  = new BehaviorSubject<boolean>(false);
  public propertySearchSubject = new Subject<string>();
  public unitSearchSubject     = new Subject<string>();

  public activeSearch = new BehaviorSubject(false);
  

  public propertyData: Observable<PropertyModel[]>;
  public unitData: Observable<UnitModel[]>;

  public property = new BehaviorSubject<PropertyModel|null>(null);
  public unit     = new BehaviorSubject<UnitModel|null>(null);

  public has_security_code = false;

  public forceChange = new EventEmitter();

  protected subscriptions: Subscription[] = [];

  public sendActiveRequest = new BehaviorSubject<boolean>(false);

  constructor(
    private registrationService: RegistrationService,
    private propertyService: PropertyService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private notif: ToastrService,
    private translate: TranslateService,
    @Inject('propertyDataSource') private propertyDataSource: DataSourceService<PropertyModel>,
    @Inject('unitsDataSource')    private unitsDataSource: DataSourceService<UnitModel>,
    public dialog: MatDialog,
  ) {
    //get data for unit and property dropdowns
    this.propertyData  = this.propertyDataSource.data;
    this.unitData      = this.unitsDataSource.data;

    //set propery from code form
    const serviceData = this.registrationService.registrationFormData.getValue();

    this.formGroup = new UntypedFormGroup({
      property_id: new UntypedFormControl(
        serviceData.property_id ? {value: serviceData.property_id} : '', 
        [Validators.required]
      ),
      unit: new UntypedFormControl(null, [Validators.required]),
      postal_code: new UntypedFormControl(
        null,
        {
          validators: [PqValidators.validPostalCode()],
          updateOn: 'change'
        }
      ),
    });
    

    //set up search box for property and unit
    this.subscriptions.push(
      this.propertySearchSubject.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        withLatestFrom(this.propertyDataSource.filter)
      ).subscribe(([s, filter]) => {
        this.propertyDataSource.filter.next(filter.setParam('address', s||''));
      })
    );

    this.subscriptions.push(
      this.unitSearchSubject.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        withLatestFrom(this.unitsDataSource.filter)
      ).subscribe(([s, filter]) => {
        this.unitsDataSource.filter.next(filter.setParam('unit', s||''));
      })
    );

    //get properties with postal code (when postal code is valid)
    this.subscriptions.push(
      this.formGroup.controls.postal_code.valueChanges.pipe(
        debounceTime(500),
        map((s: string) => s.replace(/[^a-zA-Z0-9]/g, '')),
        distinctUntilChanged(),
        filter(() => this.formGroup.controls.postal_code.valid),
        withLatestFrom(this.propertyDataSource.filter),
      ).subscribe(([p, filter]) => {
        //clear property and unit
        this.formGroup.controls.property_id.setValue(null);
        this.onPropertyChange(null);
        this.activeSearch.next(true);
        this.propertyDataSource.filter.next(filter.setParam('postal_code', p||''));
      })
    );

    //clear properties when postal code is invalid
    this.subscriptions.push(
      this.formGroup.controls.postal_code.statusChanges.pipe(
        filter((status)=>status === 'INVALID'),
        withLatestFrom(this.propertyDataSource.filter.pipe()),
      ).subscribe(([status, filter]) => {
        // this.propertyDataSource.filter.next(filter.setParam('postal_code', '')); //prevent call for invalid postal codes
        this.formGroup.controls.property_id.setValue(null);
        this.onPropertyChange(null);
      })
    );

    this.propertyData.subscribe((data) => {
      this.activeSearch.next(false);
      this.showPropertyDropdown.next(data.length > 1);

      if(data.length == 1){
        this.onPropertyChange(data[0]);
        this.formGroup.controls.property_id.setValue(data[0].id);
      }
    });

  }

  unitChanged(event: UnitModel){
    event?.id ? (this.unitsDataSource.service as UnitService).hasSecurityCode(event.id)
      .subscribe(data => {
        this.has_security_code = data;
      }) : null
  }

  ngOnInit(): void {
    this.registrationService.clearRegistrationFormData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((i) => i && i.unsubscribe());
  }

  ngAfterViewInit(): void {
  }

  acScroll(event: any) {
    console.log(event);
  }

  loadMoreProperties() {
    this.propertyDataSource.fetchNextPage.emit(); 
  }

  loadMoreUnits() {
    this.unitsDataSource.fetchNextPage.emit();
  } 

  onPropertyChange($event: PropertyModel|null) {
    if($event) {
      this.property.next($event);
      (this.unitsDataSource.service as UnitService).property_id = $event.id;
    } else {
      this.property.next(null);
      (this.unitsDataSource.service as UnitService).property_id = undefined;
    }

    this.unitsDataSource.pages.next([]);
    this.unitsDataSource.fetchNextPage.emit();
    this.formGroup.controls.unit.setValue(null);
  }

  onSubmit() {
    if(this.formGroup.valid) {
      this.registrationService.setRegistrationFormData({...this.formGroup.value, property: this.property.getValue()});
      
      (!this.has_security_code && this.property.getValue()?.public_registration) ?  this.router.navigate(['register']) : this.router.navigate(['code']);
    } else {
      this.notif.error(this.translate.instant('Error'), this.translate.instant('Please fill all required fields'));
      this.formGroup.markAllAsTouched();
    }
    return false;
  }

  openPropertyNotFoundDialog(): void {
   this.dialog.open(PropertyNotFoundDialog,  {
      height: '240px',
      width: '500px',
    });
  }
  
}

