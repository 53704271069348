<div class="download-app-container"  *ngIf="customisation.config|async; let c">
    <ng-container *ngIf="c.android_application_link || c.ios_application_link">
        <span>{{'Download the App' | translate}}</span>
        <a *ngIf="c.android_application_link" [href]="c.android_application_link" target="_blank">
            <div class="img"><img src="assets/images/app-android.png"></div>
        </a>
        <a *ngIf="c.ios_application_link" [href]="c.ios_application_link" target="_blank">
            <div class="img"><img src="assets/images/app-ios.png"></div>
        </a>
    </ng-container>
</div>