// I would put the functionality of this service in payment-method-service or new-payment-method-service but couldn't inject them into add-new-button.component.ts
import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {PaymentMethodModel} from "../../../tenant/payment/payment-method/payment-method.model";

@Injectable()
export class NewPaymentMethodService {

  public newPaymentMethod = new Subject<PaymentMethodModel>();
  constructor() { }
}
