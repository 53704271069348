import {Directive, ElementRef, HostBinding, OnDestroy, OnInit} from "@angular/core";
import {BehaviorSubject, Subscription} from "rxjs";

@Directive({
  selector: '[loadable]'
})
export class LoadableDirective implements OnInit, OnDestroy {

  public resolved                    = new BehaviorSubject(false);
  @HostBinding('class') elementClass = 'loadable';

  subscriptions: Subscription[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s ? s.unsubscribe() : null);
  }
}