import { inject } from "@angular/core";
import { CanActivateFn, Router, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { RegistrationService } from "../../login/registration.service";
import { TranslateService } from "@ngx-translate/core";

export const RegisterGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const registrationService: RegistrationService = inject(RegistrationService);
  const router: Router = inject(Router);
  const notif: ToastrService = inject(ToastrService);
  const translate: TranslateService = inject(TranslateService);

  if (!registrationService.registrationFormData.getValue().property_id) {
    notif.error(translate.instant("No Property Selected"));
    return of(router.createUrlTree(["/property"]));
  } else if (
    !registrationService.registrationFormData.getValue().property
      ?.public_registration &&
    !registrationService.registrationFormData.getValue().security_code
  ) {
    return of(router.createUrlTree(["/code"]));
  } else {
    return of(true);
  }
};
