import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {PqValidators} from "../../shared/validators/pq.validators";
import {BehaviorSubject, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../shared/services/auth.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  formControl = new UntypedFormControl(null, [Validators.required, Validators.email])
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    email: this.formControl,
    password:     new UntypedFormControl(
      '',
      [
        Validators.required,
        Validators.minLength(6),
      ]
    ),
    password_confirmation: new UntypedFormControl(
      '',
      [
        Validators.required,
        Validators.minLength(6),
        PqValidators.notSame('password'),
      ]
    ),
    token: new UntypedFormControl(''),
    terms: new UntypedFormControl(false, [Validators.requiredTrue]),
  });

  showPassword = new BehaviorSubject(false);
  showPasswordConfirmation = new BehaviorSubject(false);
  disableButton = new BehaviorSubject<boolean>(false);
  subscriptions: Subscription[] = [];


  constructor(
    public activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private notif: ToastrService,
    private translate: TranslateService,
  ) {
    this.subscriptions.push(
      activatedRoute.params.subscribe(
        p => {
          if(p.token) {
            this.formGroup.controls['token'].setValue(p.token);
          }
        }
      )
    );

    this.formGroup.get('password')?.valueChanges.subscribe(() => {
      this.formGroup.get('password_confirmation')?.updateValueAndValidity();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s ? s.unsubscribe() : null);
  }

  send() {
    console.log(this.formGroup.value);
    this.formGroup.markAllAsTouched();
    if(this.formGroup.valid) {
      this.authService.resetPassword(this.formGroup.value).subscribe({
        next: r => {
          this.notif.success(
            undefined,
            this.translate.instant('Success')
          );
          this.router.navigate(['/login']);
        },
        error: (d: HttpErrorResponse) => {
          if (d.error && d.error.password[0]) {
            this.notif.error(
                undefined,
                d.error.password[0]
            );
          } else {
            this.notif.error(
                undefined,
                'Unable to set new password'
            );
          }
        },
      })
    }
  }

  toggleShowPassword() {
    this.showPassword.next(!this.showPassword.getValue());
  }

  toggleShowPasswordConfirmation() {
    this.showPasswordConfirmation.next(!this.showPasswordConfirmation.getValue());
  }

}
