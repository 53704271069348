import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorNotFoundComponent} from "./error-not-found/error-not-found.component";
import {SiteLayoutComponent} from "./layout/site-layout/site-layout.component";
import {ErrorComponent} from "./error/error.component";
import {MessageLayoutComponent} from "./layout/message-layout/message-layout.component";
import {LoginLayoutComponent} from "./layout/login-layout/login-layout.component";
import {LoginFormComponent} from "./login/login-form/login-form.component";
import {RegisterFormComponent} from "./login/register-form/register-form.component";
import {CodeFormComponent} from "./login/code-form/code-form.component";
import {ResetComponent} from "./login/reset/reset.component";
import {EmailComponent} from "./login/email/email.component";
import {PropertyComponent} from "./login/property/property.component";
import {DashboardLayoutComponent} from "./layout/dashboard-layout/dashboard-layout.component";
import {
  SuppressMobileMatCardLayoutComponent
} from "./layout/supress-mobile-mat-card-layout/suppress-mobile-mat-card-layout.component";
import {AuthGuard} from "./shared/guards/auth.guard";
import {AccessGuard} from "./shared/guards/access.guard";
import {OnboardingNotFinishedGuard} from "./shared/guards/onboarding-not-finished.guard";
import {ApprovedGuard} from "./shared/guards/approved.guard";
import {AuthNotGuard} from "./shared/guards/auth-not.guard";
import {PropertySelected} from "./shared/guards/property-selected.guard";
import { RegisterGuard } from "./shared/guards/register.guard";
import {EmailInviteResolver} from "./login/email/email-invite.resolver";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {ViewLayoutComponent} from "./layout/view-layout/view-layout.component";
import {TermsOfServiceComponent} from "./terms-of-service/terms-of-service.component";
import {ResetPasswordComponent} from "./login/reset-password/reset-password.component";
import {PendingChangesGuard} from "./shared/guards/pending-changes.guard";

const routes: Routes = [
  {
    path: '',
    component: SiteLayoutComponent,
    canActivate: [AuthGuard, OnboardingNotFinishedGuard],
    canActivateChild: [ApprovedGuard, AccessGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/dashboard'
      },
      // {
      //   path: 'tenant',
      //   loadChildren: () => import('./tenant/tenant.module').then(m => m.TenantModule)
      // },
      {
        path: 'payment',
        loadChildren: () => import('./tenant/payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: 'amenity',
        loadChildren: () => import('./tenant/amenity/amenity.module').then(m => m.AmenityModule)
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./tenant/maintenance/maintenance.module').then(m => m.MaintenanceModule)
      },
      {
        path: 'parcel',
        loadChildren: () => import('./tenant/parcel/parcel.module').then(m => m.ParcelModule)
      },
      {
        path: 'document',
        loadChildren: () => import('./tenant/document/document.module').then(m => m.DocumentModule)
      },
      {
        path: 'promotion',
        loadChildren: () => import('./tenant/promotion/promotion.module').then(m => m.PromotionModule)
      },
      {
        path: 'insurance',
        loadChildren: () => import('./tenant/insurance/insurance.module').then(m => m.InsuranceModule)
      },
      {
        path: 'upcoming-event',
        loadChildren: () => import('./tenant/upcoming-event/upcoming-event.module').then(m => m.UpcomingEventModule)
      },
      {
        path: 'survey',
        loadChildren: () => import('./tenant/survey/survey.module').then(m => m.SurveyModule)
      }
    ]
  },
  {
    path: 'public-survey',
    component: LoginLayoutComponent,
    loadChildren: () => import('./tenant/survey/survey.module').then(m => m.SurveyModule)
  },
  {
    path: 'profile',
    component: SuppressMobileMatCardLayoutComponent,
    canActivate: [AuthGuard, OnboardingNotFinishedGuard, ApprovedGuard],
    canActivateChild: [AccessGuard],
    loadChildren: () => import('./tenant/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'dashboard',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard, OnboardingNotFinishedGuard, ApprovedGuard],
    canActivateChild: [AccessGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./tenant/dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },
  {
    path: 'message',
    component: MessageLayoutComponent,
    canActivate: [AuthGuard, OnboardingNotFinishedGuard, ApprovedGuard],
    canActivateChild: [AccessGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./tenant/message/message.module').then(m => m.MessageModule)
      },
    ]

  },
  {
    path: 'onboarding',
    component: LoginLayoutComponent,
    canActivate: [],
    children: [
      {
        path: '',
        loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule)
      }
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    canActivateChild: [AuthNotGuard],
    children: [
      {
        path: 'login',
        component: LoginFormComponent
      },
      {
        path: 'property',
        component: PropertyComponent
      },
      {
        path: 'register',
        component: RegisterFormComponent,
        canDeactivate: [PendingChangesGuard],
        canActivate: [RegisterGuard],
      },
      {
        path: 'code',
        component: CodeFormComponent,
        canActivate: [PropertySelected],
      },
      {
        path: 'reset',
        component: ResetComponent
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordComponent
      },
      {
        path: 'email',
        component: EmailComponent,
        resolve: {
          inviteMeta: EmailInviteResolver
        }
      },
      {
        path: 'surveys',
        loadChildren: () => import('./tenant/survey/survey.module').then(m => m.SurveyModule)
      }
    ]
  },
  {
    path: '',
    component: ViewLayoutComponent,
    children: [
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'terms-of-service',
        component: TermsOfServiceComponent
      },
    ]
  },
  {
    path: '404',
    component: ErrorNotFoundComponent
  },
  {
    path: '500',
    component: ErrorComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    // {enableTracing: true, scrollPositionRestoration: "top"}
    {scrollPositionRestoration: "top"}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
