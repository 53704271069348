import { Injectable } from "@angular/core";
import {
  BiometricAuth,
  BiometryError,
} from "@aparajita/capacitor-biometric-auth";
import { SecureStorage } from "@aparajita/capacitor-secure-storage";
import { Capacitor } from "@capacitor/core";
import { Dialog } from "@capacitor/dialog";
import { Preferences } from "@capacitor/preferences";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, filter, from } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BiometricService {
  /** biometry available on device */
  biometryAvailable = new BehaviorSubject(false);

  /** biometry enrolled by user. If null, it  means unset, user has not enrolled or disenrolled */
  biometryEnrolled = new BehaviorSubject<boolean | null>(null);

  server = "myportal.payquad.com";

  constructor(
    private translate: TranslateService
  ) {
    this.checkBiometryAvailableAndSet(); // update biometryAvailable + biometryEnrolled
  }

  /**
   * Check if biometric authentication is available on device and convert result to observable, updating
   * <biometryAvailable> BehaviorSubject.
   */
  checkBiometryAvailableAndSet() {
    // TODO Biometric is a blocker on web, dialog does not show up
    if (!Capacitor.isNativePlatform() || Capacitor.getPlatform() === 'web') {
      return;
    }

    // Check if device supports biometry
    from(BiometricAuth.checkBiometry()).subscribe({
      next: (r) => {
        this.biometryAvailable.next(r.isAvailable);
      },
      error: (e) => {
        this.biometryAvailable.next(false);
        console.log(e);
      },
    });

    from(this.getBiometryEnrolledFromPreferences()).subscribe({
      next: (r) => {
        this.biometryEnrolled.next(r);

        this.biometryEnrolled.pipe(filter(v => v !== r)).subscribe(v => this.setBiometryEnrolledFromPreferences(v));
      },
      error: (e) => {
        this.biometryEnrolled.next(null);
        console.log(e);
      },
    });
  }

  // ask for fingerprint
  async authenticateBiometric() {
    const verified = await BiometricAuth.authenticate({
      reason: this.translate.instant("Access your Payquad account"),
      //   title: this.translate.instant("Biometric log in"),
      //   subtitle: this.translate.instant("Please verify your identity"),
      //   description: this.translate.instant("Please verify your identity"),
      cancelTitle: this.translate.instant("Cancel"),
      //   maxAttempts: 5,
      allowDeviceCredential: true, // allow user to use password/PIN
    })
      .then(() => true)
      .catch((e: BiometryError) => {
        console.log("ERROR CODE: ", e);
        return false;
      });

    return verified;
  }

  /**
   * Handles the biometric onboarding process which follows login. Only called if user needs to be enrolled.
   */
  async enrollBiometric(): Promise<boolean> {
    // ask for biometry enrollment
    const { value } = await Dialog.confirm({
      title: this.translate.instant("Use biometric login for quick access?"),
      message: this.translate.instant(
        "You can use biometric login to quickly access your account without having to enter your password each time."
      ),
      okButtonTitle: this.translate.instant("Use biometric login"),
      cancelButtonTitle: this.translate.instant("Not now"),
    });

    if (!value) {
      await Dialog.alert({
        title: this.translate.instant("Biometric enrollment skipped"),
        message: this.translate.instant(
          'You can enable biometric login under "My Profile"'
        ),
      });
      this.biometryEnrolled.next(false);
      this.setBiometryEnrolledFromPreferences(false);
      return false;
    }

    const verified = await this.authenticateBiometric();

    // if fingerprint verification failed, return
    if (!verified) {
      await Dialog.alert({
        title: this.translate.instant("Biometric verification failed"),
        message: this.translate.instant(
          'You can enable biometric login under "My Profile"'
        ),
      });
      return false;
    }

    // if fingerprint verification succeeded, delete previous credentials and set new credentials
    if (verified) {
      await Dialog.alert({
        title: this.translate.instant("Enrollment successful"),
        message: this.translate.instant(
          'You can disable biometric login under "My Profile"'
        ),
      });
      // set appropriate flags
      this.biometryEnrolled.next(true);
      this.setBiometryEnrolledFromPreferences(true);
    }

    return true;
  }

  async disenrollBiometric(): Promise<void> {
    this.deleteCredentials();
    this.biometryEnrolled.next(false);
    this.setBiometryEnrolledFromPreferences(false);
  }

  async deleteCredentials() {
    await SecureStorage.remove(this.server);
  }

  async setCredentials(email: string, password: string) {
    await SecureStorage.set(this.server, {
      username: email,
      password: password,
    });
  }

  async getCredentials(): Promise<Credentials | null> {
    return await SecureStorage.get(this.server) as { username: string, password: string };
  }

  /** Get the "biometry enabled" preference from Capacitor's allotted localStorage. */
  async getBiometryEnrolledFromPreferences(): Promise<boolean | null> {
    const { value } = await Preferences.get({ key: 'biometrySet-1' });

    if (value === null) {
      return null;
    }

    return value === 'true';
  }

  /** Set the "biometry enabled" preference from Capacitor's allotted localStorage */
  // if the user enrolls in biometric
  async setBiometryEnrolledFromPreferences(value: boolean | null) {
    if (value === null) {
      await Preferences.remove({ key: 'biometrySet-1' });
    } else {
      await Preferences.set({
        key: 'biometrySet-1',
        value: value.toString()
      });
    }
  }
}
export interface Credentials {
  username: string;
  password: string;
}