import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private isMobileApp = Capacitor.isNativePlatform()

  constructor() { }

  async getItem(key: string) {
    if (this.isMobileApp) {
      const res = await Preferences.get({ key })
      return res.value
    } else {
      return localStorage.getItem(key)
    }
  }

  setItem(key: string, value: string) {
    if (this.isMobileApp) {
      Preferences.set({
        key,
        value,
      })
    } else {
      localStorage.setItem(key, value)
    }
  }

  removeItem(key: string) {
    if (this.isMobileApp) {
      Preferences.remove({ key })
    } else {
      localStorage.removeItem(key)
    }
  }
}
