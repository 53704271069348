import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthService } from "../services/auth.service";

export const AuthNotGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router: Router = inject(Router);
  const authService: AuthService = inject(AuthService);

  return authService.isLoggedIn.pipe(
    switchMap(isLoggedIn => {
      if (!isLoggedIn) {
        return of(!isLoggedIn);
      }
      return of(router.parseUrl('/'))
    })
  )
}