import { HttpParams } from "@angular/common/http";
import {RestHttpParam, RestHttpParams} from "./interfaces/data/rest.http-param.type";
import {FilterInterface} from "./interfaces/data/filter.interface";

export class RestFilterModel implements FilterInterface {
  constructor(
    protected params: RestHttpParams
  ) {}

  isEmpty(): boolean {
    return !Object.keys(this.params).length || Object.values(this.params).reduce<boolean>((acc, item) => acc && !item, true);
  }
  
  setParam(name: string, value: RestHttpParam): RestFilterModel {
    this.params[name] = value;
    return this;
  }
  
  // setParams(
  //   params: RestHttpParams
  // ): RestFilterModel {
  //   Object.assign(this.params, params);
  //   return this;
  // }
  
  getParam(name: string): RestHttpParam {
    return this.params[name];
  }
  
  // getParams(): RestHttpParams {
  //   return {...this.params};
  // }
  
  buildHttpParams(httpParams: HttpParams): HttpParams {
    //todo: realise filters
    httpParams = Object.entries(this.params).reduce<HttpParams>(
      (cur, i) => {
        return cur.append(`filter[${i[0]}]`, i[1].toString())
      },
      httpParams
    )
    return httpParams;
  }
}