<mat-card
         
        class="form-mat-card custom-message"
        [ngClass]="{'mat-card-suppress': suppressMatCard}"
        *ngIf="(customization.config|async)?.login_register_message as m"
>
    <div
            class="accent-font-3 body3"
            [innerHtml]="m">
    </div>
</mat-card>

<mat-card   class="form-mat-card" [ngClass]="{'mat-card-suppress': suppressMatCard}">
    <div class="title" *ngIf="templateTitle">{{'Register' | translate}}</div>
    <div class="subtitle">
        
        <div class="login-with-code" *ngIf="templateLoginCodeLink && (showLoginWithCodeText|async)">
            <span>or</span>
            <mat-icon svgIcon="pq:input_password_protection" class="accent-primary-1"></mat-icon>
            <span class="notice-link" routerLink="/code">{{'Login With Code' | translate}}</span>
        </div>
    </div>
    <form class="pq-form" (submit)="onSubmit()" [formGroup]="formGroup">
        <div class="one-row one-row-2col-same-size">
            <div class="label-and-mat-form-field">
                <label>{{'First Name' | translate}}</label>
                <mat-form-field>
                    <input matInput formControlName="first_name" [placeholder]="'First Name' | translate">
                    <mat-error>
                        <span *ngIf="formGroup.controls['first_name']?.errors?.required">{{'First Name is required field' | translate}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="label-and-mat-form-field">
                <label>{{'Last Name' | translate}}</label>
                <mat-form-field>
                    <input matInput formControlName="last_name" [placeholder]="'Last Name' | translate">
                    <mat-error>
                        <span *ngIf="formGroup.controls['last_name']?.errors?.required">{{'Last Name is required field' | translate}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <label>{{'Phone' | translate}}</label>
        <mat-form-field class="full-width-field">
            <input matInput placeholder="1234567890" formControlName="phone_number">
            <mat-error>
                <span *ngIf="formGroup.controls['phone_number']?.errors?.required">{{'Phone is required field' | translate}}</span>
                <span *ngIf="formGroup.controls['phone_number']?.errors?.pattern">{{'Not a valid phone number' | translate}}</span>
                <!-- <span *ngIf="formGroup.controls['phone_number']?.errors">{{'Phone number is invalid' | translate}}</span> -->
            </mat-error>
        </mat-form-field>

        <label>{{'Email' | translate}}</label>
        <mat-form-field class="full-width-field">
            <input matInput placeholder="example@domain.com" formControlName="email">
            <mat-error>
                <span *ngIf="formGroup.controls['email']?.errors?.required">{{'Email is required field' | translate}}</span>
                <span *ngIf="formGroup.controls['email']?.errors?.email">{{'Not valid email' | translate}}</span>
            </mat-error>
        </mat-form-field>

        <label>{{'Create Password' | translate}}</label>
        <mat-form-field class="full-width-field mat-form-field-button-suffix">
            <input
                    matInput
                    formControlName="password"
                    autocomplete="new-password"
                    [type]="(showPassword | async) ? 'text' : 'password'"
                    [placeholder]="'Password, at least 6 characters' | translate"
            />
            <button type="button" matSuffix mat-icon-button tabindex="-1">
                <mat-icon svgIcon="pq:eye" (click)="toggleShowPassword()"></mat-icon>
            </button>
            <mat-error>
                <span *ngIf="formGroup.controls['password']?.errors?.required">{{'Password is required field' | translate}}</span>
                <span *ngIf="formGroup.controls['password']?.errors?.minlength">{{'Min length of 6 characters' | translate}}</span>
                <span *ngIf="formGroup.controls['password']?.errors?.pattern">{{'Must contain at least one number' | translate}}</span>
            </mat-error>
        </mat-form-field>

        <label>{{'Confirm Password' | translate}}</label>
        <mat-form-field class="full-width-field mat-form-field-button-suffix">
            <input
                    matInput
                    formControlName="password_confirmation"
                    autocomplete="new-password"
                    [type]="(showPasswordConfirmation | async) ? 'text' : 'password'"
                    [placeholder]="'Repeat password' | translate"
            />
            <button type="button" matSuffix mat-icon-button tabindex="-1">
                <mat-icon svgIcon="pq:eye" (click)="toggleShowPasswordConfirmation()"></mat-icon>
            </button>
            <mat-error>
                <span *ngIf="formGroup.controls['password_confirmation']?.errors?.required">{{'Password is required field' | translate}}</span>
                <span *ngIf="formGroup.controls['password_confirmation']?.errors?.minlength">{{'Min length of 6 characters' | translate}}</span>
                <span *ngIf="formGroup.controls['password_confirmation']?.errors?.notSame">{{'Password mismatch' | translate}}</span>
            </mat-error>
        </mat-form-field>
        
        <div class="one-row one-row-stop-break one-row-small-gap body4">
            <mat-checkbox color="primary" formControlName="terms">
                <span class="body4" style="line-height: 19px">
                    {{'I have read and agree to the ' | translate}}
                    <a class="accent-primary-1" href="/terms-of-service" target="_blank">{{'Terms&nbsp;of&nbsp;Service' | translate}}</a>
                    {{'and' | translate}}
                    <a class="accent-primary-1" href="/privacy-policy" target="_blank">{{'Privacy&nbsp;Policy' | translate}}</a>
                </span>
            </mat-checkbox>
        </div>
        
        <mat-error *ngIf="formGroup.controls['terms'].touched && formGroup.controls['terms']?.errors?.required">
            <span class="body4">
                {{'Need to accept Terms&nbsp;of&nbsp;Service' | translate}}
                {{'and' | translate}}
                {{'Privacy&nbsp;Policy' | translate}}
            </span>
        </mat-error>
        <ng-container *ngIf="submitButtonTemplate === undefined else submitButtonTemplate">
            <button
                    mat-flat-button
                    color="primary"
                    class="full-width-field send-button"
                    type="submit"
                    [disabled]="(sendActiveRequest|async)"
            >
                <mat-spinner [diameter]="20"
                    *ngIf="(sendActiveRequest|async)"
                ></mat-spinner>
                <span>{{'Complete' | translate}}</span>
            </button>
        </ng-container>
    </form>
    <div class="notice-container" *ngIf="templateLoginLink">
        {{'Already have an account?' | translate}} <a class="notice-link" routerLink="/login"> {{'Login' | translate}}</a>
    </div>

</mat-card>

<app-download-app *ngIf="templateDownloadApp"></app-download-app>
