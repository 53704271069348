import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: 'ng-template[templateType]'
})
export class TemplateTypedDirective<TypeToken> {

  // how you tell the directive what the type should be
  @Input('templateType')
  typeToken?: TypeToken;

  // the directive gets the template from Angular
  constructor(private contentTemplate: TemplateRef<TypeToken>) {
  }

  // this magic is how we tell Angular the context type for this directive, which then propagates down to the type of the template
  static ngTemplateContextGuard<TypeToken>(dir: TemplateTypedDirective<TypeToken>, ctx: unknown): ctx is TypeToken{ return true; }

}
