import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {MobileService} from "./shared/services/mobile.service";
import {NavigationEnd, Router} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {AccessMenuService} from "./shared/services/access-menu.service";
import {UserService} from "./shared/services/user.service";
import {take} from "rxjs/operators";
import { Capacitor } from '@capacitor/core';
import { DOCUMENT } from '@angular/common';
import { AppVersionUpdatePromptComponent } from './shared/components/app-version-update-prompt/app-version-update-prompt.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './shared/services/auth.service';
import { StorageService } from './shared/services/storage.service';
import {environment} from "../environments/environment";
import { SplashScreen } from '@capacitor/splash-screen';
import { LocalizationService } from './shared/services/localization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'cordova';
  platform = Capacitor.getPlatform()
  isMobileApp = Capacitor.isNativePlatform()
  branch = import.meta.env.NG_APP_BRANCH_NAME;
  backend = environment.apiBase.replace('https', '')
      .replace('http', '')
      .replace('://', '')
      .replace('/', '')
  ;
  @HostListener('window:resize', ['$event'])
  onResize(e: any) {
    this.mobileService.cssMaxWidth.next(parseInt(e?.target?.innerWidth));
  }

  async setLanguage() {
    const storedLanguage = await this.storageService.getItem('language')
    const browserLanguage = navigator.language.substring(0,2);
    if (storedLanguage) {
      this.locale.setLang(storedLanguage === 'fr' ? 'fr' : 'en');
    } else if (['fr', 'en'].some(i => i === browserLanguage)) {
      this.locale.setLang(browserLanguage === 'fr' ? 'fr' : 'en');
    } else {
      this.locale.setLang('en');
    }
  }

  async checkAppVersion() {
    if(!this.isMobileApp) return 
    const appVersionCheck:any = await this.authService.checkAppVersion()
    if(!appVersionCheck.success) {
      this.dialog.open(AppVersionUpdatePromptComponent)
    }
  }

  private registerIcons(iconRegistry: MatIconRegistry, _sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'home',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/Home, House, Line.svg')
  );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'credit_card_hand_cursor',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Payments, Finance/credit-card-hand-cursor.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'credit_card_checkmark',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Payments, Finance/credit-card-checkmark.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'screwdriver_ruler',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Construction, Tools/screwdriver-ruler.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'expand_more',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Arrows, Diagrams/Arrow.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'email_letter',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Emails/Mail, Email, Letter.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'language',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/Earth, Home, World.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'phone_call',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Phones/Phone, Call.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'letter',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Emails/Emails, Letter, Mail.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'hand_key',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Real Estate/hand-key.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'parcel',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Delivery/Delivery, Shipment, Packages.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'discount',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Shopping, Ecommerce/Sale, Discount, Promotion.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'protected',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Protection, Security/Shield, Protected.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'documents_file_text',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Files/documents-file-text.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'user_profile',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/User/User, Profile.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'home_house_message_chat',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Real Estate/home-house-message-chat.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'settings_round',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/settings-round.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'earth_cursor_select',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Navigation, Maps/earth-cursor-select.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'login_logout_arrow',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/login-logout-arrow.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'undo',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Arrows, Diagrams/Arrow, Undo.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'rotate_refresh_loading_2',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/rotate-refresh-loading.2.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'rotate_refresh_loading',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/rotate-refresh-loading.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'calendar_schedule_list',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/calendar-chedule-list.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'pen_edit',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Content, Edit/Pen, Edit.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'pen_edit_no_border',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Content, Edit/Pen, Edit, No border.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'calendar',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Iconly/Calendar.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'calendar_schedule_credit_card',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Payments, Finance/calendar-schedule-credit-card.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'bank_shield_protection_checkmark',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Payments, Finance/bank-shield-protection-checkmark.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'credit_card',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Payments, Finance/Credit card.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'calendar_clock',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/School, Learning/calendar-clock.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'plus',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/Plus, Add.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'filter',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/Filter, Sort.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'lens',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Simple/lens.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'close',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Content, Edit/close.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'skyscraper_modern_1',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Building, Construction/skyscraper-modern-1.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'checkmark_circle_1',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/checkmark-circle-1.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'checkmark_circle',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/checkmark-circle.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'dollar_circled',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Payments, Finance/dollar-circled.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'trash',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/trash-delete-bin-2.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'chevron_left',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Arrows, Diagrams/chevron_left.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'chevron_right',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Arrows, Diagrams/chevron_right.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'bank_refresh',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Payments, Finance/Bank, Refresh, Reload.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'calendar_add',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/Calendar, Schedule, Add.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'info',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/Infornation, Info.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'credit_card_mastercard',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Payments, Finance/credit-card-mastercard.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'bank_customer',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Payments, Finance/bank-customer.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'credit_card_lock_protection',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Payments, Finance/credit-card-lock-protection.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'mailbox_inbox_archive',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/mailbox-inbox-archive.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'send_message',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/Send message, Send, Share.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'folder_archive',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Office, Work/folder-archive.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'mailbox_archive_checkmark_1',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/mailbox-archive-checkmark-1.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'mailbox_unarchive',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/mailbox-unarchive.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'modern_building',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Building, Construction/modern-building.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'reply',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Emails/Reply, Emails, Letter, Mail.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'text_arrow_edit_square',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/text-arrow-edit-square.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'paper_clip_attache',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Office, Work/paper-clip-attache.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'paper_clip_attache_stroke',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Office, Work/paper-clip-attache-stroke.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'pdf',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Documents/pdf.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'doc',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Documents/doc.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'jpg',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Documents/jpg.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'png',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Documents/png.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'tag2_close',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Tags/Type 2/close.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'arrow_left',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Arrows, Diagrams/Arrow-left.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'arrow_right',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Arrows, Diagrams/Arrow-right.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'dollar_payments_conversion',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Payments, Finance/dollar-payments-conversion.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'ticket',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Shopping, Ecommerce/Ticket.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'printer',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/Printer.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'calendar_simple',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/calendar-simple.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'eye',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Simple/eye.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'eye_filled',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Simple/eye_filled.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'input_password_protection',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/input-password-rotection.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'house_find',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Building, Construction/house_find.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'modern-building',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Building, Construction/modern-building.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'horn',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Music, Audio/Horn, Trumpet.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'double_check',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/Double Check.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'dashboard_calendar',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Dashboard/Calendar.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'dashboard_calling',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Dashboard/Calling.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'dashboard_delivery',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Dashboard/Delivery, Shipment.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'dashboard_wallet',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Dashboard/Wallet.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'add',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/add.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'history_toggle_off',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/history_toggle_off.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'dashboard_message',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Dashboard/Message.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'dashboard_shield_done',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Dashboard/Shield Done.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'chevron_up',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Arrows, Diagrams/chevron_up.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'chevron_down',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Arrows, Diagrams/chevron_down.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'chat',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Emails/Messages, Chat.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'arrow_up',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Arrows, Diagrams/arrow_up.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'arrow_down',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Arrows, Diagrams/arrow_down.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'image_picture',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/image-picture.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'camera',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/camera.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'code_text',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/Code, Text.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'link',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/link.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'basket',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Shopping, Ecommerce/Basket.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'download_arrow',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/download-arrow.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'paper_upload',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Office, Work/paper-upload.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'paper_download',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Office, Work/Paper Download.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'support_chat',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Support, Help, Question/Messages, Chat.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'self_tapper_square',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Construction, Tools/self-tapper-square.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'card_amex',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Card/Amex.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'card_diners_club',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Card/DinersClub.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'card_discover',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Card/Discover.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'card_jcb',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Card/JCB.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'card_mastercard',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Card/Mastercard.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'card_visa',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Card/Visa.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'logout-arrow',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/exit-log-out-arrow.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'profile_circled',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/User/profile_circled.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'clock_watch',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/clock-watch.svg')
    );
    iconRegistry.addSvgIconInNamespace(
      'pq',
      'help',
      _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/Interface, Essential/Question.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'update',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/update.svg')
    );
    iconRegistry.addSvgIconInNamespace(
        'pq',
        'fingerprint',
        _sanitizer.bypassSecurityTrustResourceUrl('/styles/icons/User/fingerprint.svg')
    )
  }

  constructor(
      private mobileService: MobileService,
      private router: Router,
      public userService: UserService,
      private httpClient: HttpClient,
      private translate: TranslateService,
      private accessMenuService: AccessMenuService,
      iconRegistry: MatIconRegistry,
      _sanitizer: DomSanitizer,
      @Inject(DOCUMENT) private document: Document,
      private dialog: MatDialog,
      private authService: AuthService,
      private storageService: StorageService,
      private locale: LocalizationService
  ) {
    this.setLanguage();
    this.registerIcons(iconRegistry, _sanitizer);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.rudderanalytics.page('PayQuad Tenant Web App', 'Page Loaded');
      }
    });

    this.userService.me().pipe(take(1)).subscribe(u => {
      window.rudderanalytics.identify(u.id, {
        email: u.email,
        first_name: u.first_name,
        last_name: u.last_name,
        phone: u.phone_number
      });
    });

    this.document.body.classList.add(`platform-${this.platform}`)
    
    if(this.isMobileApp) {
      this.document.body.classList.add(`platform-mobile`)
      this.checkAppVersion()
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      SplashScreen.hide();
    }, 1500);
  }
}
