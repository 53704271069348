import {BaseRestService} from "../../shared/rest/base-rest.service";
import { HttpClient } from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {API_BASE_URL, API_VERSION} from "../../shared/shared.module";
import {UnitModel} from "./unit.model";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators'

@Injectable()
export class UnitService extends BaseRestService<UnitModel, UnitModel, UnitModel, UnitModel, UnitModel> {
  // protected resourceName: string = 'properties';

  get resourceName(): string {
    return `properties/${this.property_id}/units`
  }

  public property_id?: number;

  constructor(
    http: HttpClient,
    @Inject(API_BASE_URL) baseUrl: string,
    @Inject(API_VERSION) version: string
  ) {
    super(http, baseUrl, version);
  }

  public hasSecurityCode(unit_id:number) : Observable<boolean>
  {
    return this.http.get(
      this.baseUrl + this.version + '/' + this.resourceName + '/' + unit_id +'/has-security-code',
    ).pipe(
      map(d => !!d)
    )
  }
}
