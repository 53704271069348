import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AccessService } from "../services/access.service";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

export const ApprovedGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const access: AccessService = inject(AccessService);
  const router: Router = inject(Router);

  return access.isApproved().pipe(
    switchMap(
      (c: boolean) => of(c ? true : router.parseUrl('/onboarding/pending'))
    )
  )
}