export enum AccessType {
  //------Used in main menu access-------//

  // not specified any access right
  dashboard='dashboard',
  promotions='promotions',
  insurance='insurance',
  upcomingEvents='social-events',

  // described in user_permissions in api/v2/users/me resource
  payments='payments',
  maintenanceRequests='maintenance-requests',
  amenities='amenities',
  communications='communications',
  parcels='parcels',
  documents='documents',

  //-----END Used in main menu access----//
}
