<mat-card   class="form-mat-card" *ngIf="meta|async as m">
    <div class="illustration-container">
        <img src="assets/images/illustrations/wavehand.svg" />
    </div>
    <span class="h5">{{'Welcome'|translate}}, {{m.first_name}}!</span>
    <span class="with-icon">
        <mat-icon svgIcon="pq:skyscraper_modern_1"></mat-icon>
        <span class="body2 accent-font-2">{{m.address}}, {{m.city}}, {{m.postal ? m.postal + ',' : ''}} {{m.country}}</span>
    </span>
    <span class="h6 accent-font-1">{{'We’ve already set up an account for you'|translate}}</span>
    <button mat-flat-button color="primary" class="send-button" (click)="continue(m.code, m.first_name, m.last_name, m.email)">
        <span>{{'Continue Setup' | translate}}</span>
        <mat-icon svgIcon="pq:arrow_right"></mat-icon>
    </button>
    <div class="notice-container">
        <div class="notice-link with-icon">
            <mat-icon svgIcon="pq:support_chat"></mat-icon>
            <span>{{'Contact Help' | translate}}</span>
        </div>
    </div>
</mat-card>
