import {EventEmitter, Inject, Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {API_BASE_URL, API_VERSION, OPENWEATHER_API_KEY} from "../shared.module";
import {catchError, filter, map, shareReplay, startWith, switchMap, tap,} from "rxjs/operators";
import {UserModel} from "../models/user.model";
import {BehaviorSubject, combineLatest, Observable, of} from "rxjs";
import {MessageSSEModel} from "../../tenant/message/resources/message-sse.model";
import {PropertyModel} from "../models/property.model";
import moment from "moment";
import {DateFilterFn} from "@angular/material/datepicker";
import {AuthService} from "./auth.service";
import { Injector } from "@angular/core";

@Injectable({providedIn: 'root'})
export class UserService {

  // profile = this.me();
  protected unreadEventSource?: EventSource;
  unreadOnMessage = new EventEmitter<MessageSSEModel>();
  unread = new BehaviorSubject<number>(0);
  unreadMr = new BehaviorSubject<number>(0);

  private triggerMeUpdate = new EventEmitter<boolean>();

  onboardingComplete = new BehaviorSubject<boolean|undefined>(undefined);

  meError = new BehaviorSubject<boolean|null>(null);
  user = new BehaviorSubject<UserModel | undefined>(undefined);
  _me = this.triggerMeUpdate.pipe(
    startWith(true),
    switchMap(
      () => {
        return this.http.get<UserModel>(
          this.baseUrl + this.version + '/users/me',
        ).pipe(
          tap(() => this.meError.next(false)),
          catchError((err) => {
            this.meError.next(true)
            throw err;
          })
        )
      }
    ),
    tap(d => {
      this.meError.next(false);
      this._meUpdated.emit(true)
    }),
    map((d) => ({...d, property: Object.assign(new PropertyModel(), d?.tenant.property || {})})),
    tap(u => this.onboardingComplete.next(u?.is_onboarding_complete)),
    tap(u => this.user.next(u)),
    shareReplay()
  );

  paynowAllowed = this.me().pipe(
    map((u) => !!u?.validPaymentsDates?.paynow_allowed)
  );
  paynowDates = this.me().pipe(
    map((u) => u?.validPaymentsDates?.valid_dates || [])
  );
  paynowDatesFilter = this.paynowDates.pipe(
    map(
      (availableDates) => {
        return (
          (d: Date|null)=> {
            if(!availableDates.length) {
              return moment(d).isSameOrAfter(moment(), "day") && moment(d).date() <= 28;
            }
            return moment(d).isSameOrAfter(moment(), "day") && availableDates.some(i => i === moment(d).date())  && moment(d).date() <= 28;
          }
        ) as DateFilterFn<Date|null>
      }
    )
  );
  paynowFirstAvailable = this.paynowDatesFilter.pipe(
    map(fn => {
      const date = moment();
      for (let i=0; i<32; i++) {
        if(fn(date.toDate())) {
          return date.toDate();
        }
        date.add(1, 'day')
      }
      return false
    })
  );
  paynowDatesString = this.me().pipe(
    map((u) => u?.validPaymentsDates?.paynow_dates_string || '')
  );

  _meUpdated = new EventEmitter<boolean>();

  temperature = new BehaviorSubject<number|null>(null);
  temperatureUnit = new BehaviorSubject<string|null>(null);

  constructor(
    private http: HttpClient,
    private injector: Injector,
    @Inject(API_BASE_URL) public baseUrl: string,
    @Inject(API_VERSION) public version: string,
    @Inject(OPENWEATHER_API_KEY) public openWeatherApiKey: string
  ) {
    this.unreadOnMessage.subscribe(d => {
      if (d.unread_messages) {
        this.unread.next(d.unread_messages)
      }
      if (d.unread_mr_messages) {
        this.unreadMr.next(d.unread_mr_messages)
      }
    });
  }

  initWeather() {
    this.http.get<any>(
      `${this.baseUrl}${this.version}/weather`
      // 'https://api.openweathermap.org/data/2.5/weather',
      // {
      //   params: new HttpParams({
      //     fromObject: {
      //       lat: p.coords.latitude,
      //       lon: p.coords.longitude,
      //       units: 'metric',
      //       appid: this.openWeatherApiKey,
      //     }
      //   })
      // }
    ).subscribe(r => {
      if('temp' in r?.main) {
        this.temperature.next(Math.round(r.main.temp));
        this.temperatureUnit.next(r.units);
      }
    });
  }

  me(): Observable<UserModel> {
    return this._me;
  }

  update(body: any) {
    return this.http.patch(
      this.baseUrl + this.version + '/users/me',
      body
    ).pipe(
      tap(() => this.callMeUpdate())
    );
  }

  callMeUpdate(): Observable<boolean> {
    this.triggerMeUpdate.emit(true);
    this.avatarUpdatedTimestamp.next(moment().unix());
    return this._meUpdated;
  }

  resetPassword(password_old: string, password_new: string, password_new_confirmation: string) {
    return this.http.put(
      this.baseUrl + this.version + '/users/me/password',
      {
        password_old,
        password_new,
        password_new_confirmation
      }
    )
  }

  avatarDefault = 'assets/images/avatar.svg';
  avatarLoaded = new BehaviorSubject<boolean>(false);
  avatarUpdatedTimestamp = new BehaviorSubject<number>(0);
  avatarUrl: Observable<string> = this.avatarUpdatedTimestamp.pipe(
    switchMap(
      t => this.http.get<any>(
        this.baseUrl + this.version + '/users/me/avatar',
        {observe: "body"}
      ).pipe(
        switchMap((r) => of(r.avatar_path)),
        startWith(this.avatarDefault),
        tap(() => this.avatarLoaded.next(true)),
        catchError(() => of(this.avatarDefault)),
      ),
    ),
    shareReplay()
  );
  avatarExist = combineLatest([
    this.avatarLoaded,
    this.avatarUrl
  ]).pipe(
    switchMap(
      ([loaded, url]) => {
        return of(loaded && url !== this.avatarDefault);
      }
    )
  )
  avatarSet(data: FormData) {
    return this.http.post(
      this.baseUrl + this.version + '/users/me/avatar',
      data
    ).pipe(
      tap(() => {
        this.avatarLoaded.next(false);
        this.avatarUpdatedTimestamp.next(moment().unix());
      })
    )
  }
  avatarRemove() {
    return this.http.delete(
      this.baseUrl + this.version + '/users/me/avatar'
    ).pipe(
      tap(() => {
        this.avatarLoaded.next(false);
        this.avatarUpdatedTimestamp.next(moment().unix())
      })
    );
  }


  // Server side events to update unread message count
  initSSE() {
    if(!this.unreadEventSource) {
      this.unreadEventSource = new EventSource(
        this.baseUrl + this.version + `/users/notifications` + this.injector.get(AuthService).getTokenUrlParam(),
        {withCredentials: true}
      );
      this.unreadEventSource.addEventListener('open', this.onUnreadEventSourceOpen)
      this.unreadEventSource.addEventListener('error', this.onUnreadEventSourceError)
      this.unreadEventSource.addEventListener('message', this.onUnreadEventSourceMessage.bind(this))
    }
  }

  protected onUnreadEventSourceOpen(e: Event) {
    // console.log('Open', e)
  }
  protected onUnreadEventSourceError(e: Event) {
    // console.error('Error', e)
  }
  protected onUnreadEventSourceMessage(e: MessageEvent) {
    // console.log('Message', e.data)
    this.unreadOnMessage.emit(JSON.parse(e.data));
  }
}
