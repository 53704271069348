export class PropertyModel {
  public maintenance?: {
    emergency_message: {
      message_display_option: 'popup' | 'inline',
      message: string
    },
    pro_enabled: boolean,
    hide_permission_to_enter: boolean,
    messages_enabled: boolean,
    terms: {
      text: string,
      enabled: boolean
    },
    show_24_hour: boolean,
  }

  building_info?: string;

  providers?: any

  tenants_can_message?: boolean;
  insurance_enabled?: boolean;
  additional_details?: boolean;

  recurring_amounts_enabled?: boolean;
  recurring_amounts_name?: string;
  recurring_amounts_other_name?: string;

  company?: any;

  constructor(
    public id?: number,
    public address?: string,

    public type?:        string,

    public name?:        string,
    public city?:        string,
    public province?:    string,
    public country?:     string,
    public postal_code?: string,

    public subdomain?:   string,
    public timezone?:    string,

    public style?: any,
    public merchant_account?: any,
    
    public public_registration?: boolean,
  ) {
  }

  get showMRComments(): boolean {
    return !!this.maintenance?.pro_enabled && this.maintenance.messages_enabled;
  }
}
