<div class="sidenav-header" *ngIf="matSidenav">
    <p class="h8">{{'Additional Services' | translate}}</p>
    <mat-icon (click)="matSidenav.close()">close</mat-icon>
</div>
<mat-nav-list>
    <a
            mat-list-item
            *ngFor="let item of (menuService.itemsSideNav|async)"
            [routerLink]="item.routerLink"
            (click)="matSidenav?.close()"
    >
        <span class="mat-subtitle-2">
            <span class="mat-icon-container">
                <mat-icon class="icon" [svgIcon]="item.icon"></mat-icon>
            </span>
            {{item.label | translate}}
        </span>
    </a>
</mat-nav-list>
<div class="sidenav-footer">
    <p class="mat-subtitle-2">{{'Powered by' | translate}}:</p>
    <div class="logo">
        <img src="styles/img/logo.svg"/>
    </div>
    <p class="mat-subtitle-2"><a href="/terms-of-service">{{'Terms of Service' | translate}}</a></p>
    <p class="mat-subtitle-2"><a href="/privacy-policy">{{'Privacy Policy' | translate}}</a></p>
    <p class="lang-selector">
        <a (click)="locale.setLang('en')" [ngClass]="{'lang-active': locale.currentLang === 'en'}">{{'English' | translate}}</a> | 
        <a (click)="locale.setLang('fr')" [ngClass]="{'lang-active': locale.currentLang === 'fr'}">{{'French' | translate}}</a>
    </p>
</div>