import {Toast} from "ngx-toastr";
import {Component} from "@angular/core";

@Component({
  selector: '[app-toastr]',
  templateUrl: './toastr.component.html'
})
export class ToastrComponent extends Toast {

}
