import {PaginationInterface} from "./interfaces/data/pagination.interface";
import { HttpParams } from "@angular/common/http";

export class RestPaginationModel implements PaginationInterface {
  // Attributes name for create HttpParams
  // pageParam:    string;
  // perPageParam: string;

  // Settings for HttpParams
  // page:         number;
  // perPage:      number;
  totalCount:   number;
  pageCount:    number;

  constructor(
    public page: number = 1,
    public perPage: number = 15,
    public pageParam:    string = 'page',
    public perPageParam: string = 'per-page'
  ) {
    this.page       = page;
    this.perPage    = perPage

    // todo: think about undefined
    this.totalCount = 0;
    this.pageCount  = 0;
  }

  buildHttpParams(httpParams: HttpParams): HttpParams {
    httpParams = httpParams.append(this.pageParam, this.page)
    httpParams = httpParams.append(this.perPageParam, this.perPage)
    return httpParams;
  }
}