import {PageInterface} from "./interfaces/data/page.interface";
import {BehaviorSubject, Observable, of} from "rxjs";
import {FilterInterface} from "./interfaces/data/filter.interface";
import {SortInterface} from "./interfaces/data/sort.interface";
import { HttpParams } from "@angular/common/http";
import {PaginationInterface} from "./interfaces/data/pagination.interface";
import {RestListCreate} from "./interfaces/rest.list.interface";
import {catchError, switchMap, tap} from "rxjs/operators";

export class RestPage<T> implements PageInterface<T> {

  // Create by callback function
  data: Observable<T[]>;
  loading: BehaviorSubject<boolean>;
  currentPage: BehaviorSubject<number | null>;
  totalPages: BehaviorSubject<number | null>;

  constructor(
    public request: RestListCreate<T>,
    public pagination: PaginationInterface,
    public filter: FilterInterface,
    public sort: SortInterface
  ) {
    this.loading = new BehaviorSubject<boolean>(false);
    // Accept HttpResponse, parse pagination headers and transform observable to body
    this.data = this.request(this.buildHttpParams(new HttpParams()), this.loading)
                    .pipe(
                      tap(r => {
                        this.totalPages.next(parseInt(r.headers.get('X-Pagination-Page-Count') || ''));
                        this.currentPage.next(parseInt(r.headers.get('X-Pagination-Current-Page') || ''));
                      }),
                      switchMap(r => of(r.body || [])),
                      catchError((err, caught) => {
                        return of([] as T[]);
                      })
                    );
    this.totalPages = new BehaviorSubject<number | null>(null);
    this.currentPage = new BehaviorSubject<number | null>(null);
  }

  buildHttpParams(httpParams: HttpParams): HttpParams {
    httpParams = this.pagination.buildHttpParams(httpParams);
    httpParams = this.filter.buildHttpParams(httpParams);
    httpParams = this.sort.buildHttpParams(httpParams);
    return httpParams;
  }

}