import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CustomisationService} from "../../shared/services/customisation.service";
import { LocalizationService } from 'src/app/shared/services/localization.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  now = new Date();

  @Input() layout: 'default' | 'tiny' = 'default';

  constructor(
    public customization: CustomisationService,
    public locale: LocalizationService
  ) { }

  ngOnInit(): void {
  }
}
