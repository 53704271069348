import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, Subscription} from "rxjs";
import {RegistrationService} from "../registration.service";
import {Router} from "@angular/router";
import {PropertyModel} from "../../shared/models/property.model";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-code-form',
  templateUrl: './code-form.component.html',
  styleUrls: ['./code-form.component.scss']
})
export class CodeFormComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
    this.inputs?.get(0)?.nativeElement.focus();
  }

  @ViewChildren('codeInput') inputs?: QueryList<ElementRef>;

  public formGroup = new FormGroup({
      code: new FormArray([
        new FormControl('', [Validators.required]),
        new FormControl('', [Validators.required]),
        new FormControl('', [Validators.required]),
        new FormControl('', [Validators.required]),
        new FormControl('', [Validators.required]),
      ])
    },
  );

  private subscriptions: Subscription[] = [];

  public autoSendActive = new BehaviorSubject(true);

  public autoSend = true;

  public valid = new BehaviorSubject(false);

  private first_name:string|undefined;
  private last_name:string|undefined;
  private property: PropertyModel|undefined;
  private unit:string|undefined;

  public public_registration:boolean;

  constructor(
    protected service: RegistrationService,
    protected router: Router,
    private notif: ToastrService,
    private translate: TranslateService,
  ) {
    this.property = this.service.registrationFormData.getValue().property;
    this.unit = this.service.registrationFormData.getValue().unit;
    this.public_registration = this.property?.public_registration || false;
  }

  ngOnInit(): void {
    const codeFormControlArray: FormArray = this.formGroup.get('code') as FormArray;
    if(codeFormControlArray) {
      this.subscriptions.push(
        codeFormControlArray.valueChanges.subscribe(
          (d: string[]) => {
            const codeStr = d.join('');
            if (codeStr.length < 5) {
              this.inputs?.get(codeStr.length)?.nativeElement.focus(); //focus next
            }
            if (codeStr.length === 5 && this.autoSend) {
              this.validate();
            }
            codeFormControlArray.markAsUntouched();
          }
        )
      );
    }
  }

  validate() {
    this.service.validateCode(this.formGroup.get('code')!.value.join('')).subscribe({
      next: response => {
        if(this.property?.id != response.body?.property?.id) {
          this.notif.error(
            this.translate.instant('Are you sure you selected the correct property? Redirecting to property page.'), 
            this.translate.instant('Incorrect property'),
            { timeOut: 6000 }
          );
          setTimeout(() =>{ 
            this.router.navigate(['/property']);
          }, 3000)
          return;
        }else if(this.unit != response.body?.unit?.unit) {
          this.notif.error(
            this.translate.instant('Are you sure you selected the correct unit? Redirecting to property page.'),
            this.translate.instant('Incorrect unit'),
            { timeOut: 6000 }
          );
          setTimeout(() =>{
            this.router.navigate(['/property']);
          }, 3000)
          return;
        }
        this.valid.next(true);
        this.formGroup.setErrors(null);

        this.first_name = response.body?.first_name;
        this.last_name = response.body?.last_name;
        // this.property = response.body?.property;
        // this.unit = response.body?.unit?.unit;

        const codeFA = this.formGroup.get('code') as FormArray;
        codeFA.controls.forEach(fc => fc.setErrors(null));
        codeFA.markAllAsTouched();
      },
      error: e => {
        this.valid.next(false);
        if (e.status === 422) {
          this.formGroup.setErrors({code: 'Invalid code'}, )
          const codeFA = this.formGroup.get('code') as FormArray;
          codeFA.controls.forEach(fc => fc.setErrors({'error': true}));
          codeFA.markAllAsTouched();
        }
      }
   })
  }

  send() {
    if(this.valid.getValue()) {
      this.service.setRegistrationFormData({
        security_code: this.formGroup.get('code')?.value.join(''),
        first_name: this.first_name,
        last_name: this.last_name,
        // unit: this.unit,
      });
      
      this.router.navigate(['/register']);
    }else if(this.public_registration) {
      this.router.navigate(['/register']);
    }
  }

  onKeydown(event: KeyboardEvent) {

    switch (event.key) {
      case 'Backspace':
      case 'Delete':
        // do nothing
      default:
        //go to next
        // const next = this.inputs?.find((item, index, array) => {
        //   item.
        // });
    }

    //check if all inserted and send
  }

  onFocus(event?: FocusEvent) {
    const target = event?.target as HTMLInputElement;
    if(target) {
      target.select();
    }

    const codeFormControlArray: FormArray = this.formGroup.get('code') as FormArray;
    if(codeFormControlArray) {
      codeFormControlArray.markAsUntouched();
    }
  }

  onPaste(event: ClipboardEvent) {
    const buffer = event.clipboardData?.getData('text')?.replace(' ', '')?.slice(0, 5);
    if(buffer) {
      const fa = this.formGroup.controls.code as FormArray;
      fa.controls.forEach(
        (c, i) => {
          if(buffer[i]) {
            c.setValue(buffer[i]);
          }
        }
      )
    }
  }

}
