import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { State } from "src/app/store/app.state";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AppEffects {

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private translate: TranslateService
      ) {}
}