export enum PaymentMethodType {
  'visa'          = 'visa',
  'amex'          = 'amex',
  'discover'      = 'discover',
  'mastercard'    = 'mastercard',
  'debit_visa'    = 'debit_visa',
  'direct_debit'  = 'direct_debit',
  'cash'          = 'cash',
  'check'         = 'check',
  'etransfer'     = 'etransfer',
  'n/a'     = 'n/a',
}
