export const environment = {
  production: true,
  appId: 'com.payquad',
  apiBaseUrl: 'https://dev.payquad.com/api/',
  apiBase: 'https://dev.payquad.com/',
  managerApp: 'https://dev.payquad.com/',
  apiVersion: 'v2',
  appName: 'tenant_web',
  useCsrfToken: true,

  plaidEnv: 'sandbox',
  plaidClientName: 'Payquad Account Link',
  plaidPublicKey: 'ac18c7510a6547a89c616549f75199',

  openWeatherApiKey: 'a191bfa5cda4556023437bcc8c96dc50',
};
