import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-confirm-redirect',
  templateUrl: './confirm-redirect.component.html',
  styleUrls: ['./confirm-redirect.component.scss']
})
export class ConfirmRedirectComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmRedirectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public t: TranslateService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onProceedClicked() {
    window.open(
        this.data.url,
        '_blank'
    );
    this.dialogRef.close();

  }
}
