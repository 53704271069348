<app-create-select-method
        [inline]="true"
        [illustratedIcons]="isOnboarding"
        (choice)="onMethodChosen($event)"
        [isOnboarding]="isOnboarding"
        *ngIf="(paymentMethod | async) === null"
></app-create-select-method>
<!--<app-create-select-method-bank *ngIf="step === 1"></app-create-select-method-bank>-->
<ng-container *ngIf="(isCreditMethod | async) || (isDebitMethod | async)">
    <app-create-credit-card-form
            *ngIf="(userInfo|async)?.property?.providers?.property !== 'payrix'"
            [isDebit]="(isDebitMethod | async) || false"
            (successSaved)="onSuccess()"
            (disableSubmit)="this.disableSubmit.next($event)"
            #creditCardFormComponent
    ></app-create-credit-card-form>
    <app-create-credit-card-form-payrix
            *ngIf="(userInfo|async)?.property?.providers?.property === 'payrix'"
            [isDebit]="(isDebitMethod | async) || false"
            (successSaved)="onSuccess()"
            (disableSubmit)="this.disableSubmit.next($event)"
            #creditCardFormComponentPayrix>
    </app-create-credit-card-form-payrix>
    <div class="one-row one-row-space-around pq-form" *ngIf="isOnboarding">
        <div class="mat-button-shadow" (click)="isOnboarding ? router.navigate(['/onboarding/payments']) : paymentMethod.next(null)">
            <mat-icon svgIcon="pq:chevron_left"></mat-icon>
            <span>{{'Back' | translate}}</span>
        </div>
        <button
            mat-flat-button
            color="primary"
            (click)="savePaymentMethodClick()"
            [disabled]="disableSubmit | async"
        >
            <span>{{'Save & Continue'}}</span>
            <mat-icon svgIcon="pq:arrow_right"></mat-icon>
        </button>
    </div>
</ng-container>

<ng-container *ngIf="isBankMethod | async">
    <app-create-bank-form
      (successSaved)="onSuccess()"
      (disableSubmit)="this.disableSubmit.next($event)"
      #createBankFormComponent
    ></app-create-bank-form>
    <div class="one-row one-row-space-around pq-form" *ngIf="isOnboarding">
        <div class="mat-button-shadow" (click)="paymentMethod.next(null)">
            <mat-icon svgIcon="pq:chevron_left"></mat-icon>
            <span>{{'Back' | translate}}</span>
        </div>
        <button
            mat-flat-button
            color="primary"
            (click)="createBankFormComponent.onSubmit()"
            [disabled]="disableSubmit | async"
        >
            <span>{{'Save & Continue'}}</span>
            <mat-icon svgIcon="pq:arrow_right"></mat-icon>
        </button>
    </div>
</ng-container>

