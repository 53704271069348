import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";
import {UserService} from "../../shared/services/user.service";
import {AccessMenuService} from "../../shared/services/access-menu.service";
import {CustomisationService} from "../../shared/services/customisation.service";
import { LocalizationService } from 'src/app/shared/services/localization.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit {
  @Input() sidenavAdditionalServices: MatSidenav|null = null;
  @Input() sidenavProfile: MatSidenav|null = null;

  avatar = this.userService.avatarUrl;

  constructor(
    public menuService: AccessMenuService,
    public customizationService: CustomisationService,
    private userService: UserService,
    public locale: LocalizationService
  ) { }

  ngOnInit(): void {
    this.userService.initSSE();
  }
}
