import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {LayoutService} from "../../shared/services/layout.service";
import {MobileService} from "../../shared/services/mobile.service";
import {AuthService} from "../../shared/services/auth.service";
import {filter} from "rxjs/operators";
import {Router} from "@angular/router";

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit, OnDestroy {

  layout: Observable<{toolbar: boolean, footer: boolean}> = new BehaviorSubject({toolbar: true, footer: true});
  subscriptions: Subscription[] = [];

  constructor(
    public layoutService: LayoutService,
    public mobileService: MobileService,
    private authService: AuthService,
    private router: Router
  ) {
    this.subscriptions.push(
      this.authService.getToken().pipe(
        filter(t => t === null)
      ).subscribe(() => this.router.navigate(['/login']))
    );
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s ? s.unsubscribe() : null);
  }

}
