import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import {Observable, of} from "rxjs";
import {UserService} from "../services/user.service";
import {filter, switchMap} from "rxjs/operators";

export const OnboardingNotFinishedGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const userService: UserService = inject(UserService);
  const router: Router = inject(Router);

  return userService.onboardingComplete.pipe(
    filter(f => f !== undefined),
    switchMap(
      (flag) => {
        return of(flag ? true : router.parseUrl('/onboarding'));
      }
    )
  );
}