import { Directive, HostListener, ElementRef, Input, Inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { API_BASE } from '../shared.module';

@Directive({
  selector: '[appOpenInAppBrowser]',
})
export class OpenInAppBrowserDirective {
  private isMobileApp = Capacitor.isNativePlatform()
  private domainUrl = ''

  constructor(
    private el: ElementRef,
    @Inject(API_BASE) protected baseUrl: string,
    ) {
      this.domainUrl = this.baseUrl.replace(/\/+$/, '')
  }

  @Input() token?:string | null = null
  @Input() includeDomain?:string | undefined = undefined

  @HostListener('click', ['$event'])
  onClick($event: any) {
    if(!this.isMobileApp) {
      return
    }

    $event.preventDefault()
    let url = this.el.nativeElement.getAttribute('href')

    if(!url) {
      return
    }

    if(this.token) {
      url += `?cordova=1&token=${encodeURI(this.token)}`
    }

    if(this.includeDomain) {
      url = this.domainUrl + url
    }

    this.openInAppBrowser(url)
  }

  openInAppBrowser = async (url: string) => {
    await Browser.open({ url });
  };

  // Expose baseUrl through a getter method for testing
  getBaseUrl(): string {
    return this.baseUrl;
  }

}
