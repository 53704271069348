<main class="main-container">
    <div class="logout-container accent-font-3" *ngIf="(authService.isLoggedIn|async) && this.router.url !== '/login'">
        <button mat-button class="logout" (click)="authService.logout()">
            <mat-icon svgIcon="pq:logout-arrow"></mat-icon>
            <span>{{'Logout'|translate}}</span>
        </button>
    </div>
    <div class="header-container">
        <div class="logo-container">
<!--            <img src="assets/images/logo-big.png" />-->
            <img [src]="(customization.config|async)?.logoUrl || 'assets/images/logo-big.png'" />
        </div>
        <div
                class="message"
                *ngIf="(customization.config|async)?.welcome_message; let message"
                [innerHtml]="message"
        ></div>
    </div>
    <div class="router-container">
        <router-outlet></router-outlet>
    </div>
</main>
<app-footer *ngIf="(layout | async)?.footer"></app-footer>
