import {Injectable} from "@angular/core";
import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import { Router } from "@angular/router";

export const IGNORE_TOASTR_ERROR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  ignore = [
    '/users/me/avatar',
    '/users/me',
    '/password/reset',
  ];

  constructor(
    private toastr: ToastrService,
    private router: Router,
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse, caught) => {
        console.log('errerrerrerr', err)
        if (err.status === 493) {
          this.toastr.clear();
          this.toastr.error('You may not modify this information when logged in as a tenant.', 'Error', {closeButton: true, enableHtml: true})
        }
        const ignoreToastr = req.context.get(IGNORE_TOASTR_ERROR);
        let inIgnore = false;
        this.ignore.forEach((i) => {
          return inIgnore = err.url?.endsWith(i) ? true : inIgnore;
        });

        if(!inIgnore && !ignoreToastr) {
          if (err.status === 422 && Object.values(err.error?.errors).length) {
            const errors = Object.values(err.error?.errors);
            if(errors[0] && Array.isArray(errors[0])) {
              // show first error
              this.toastr.error(errors[0][0], 'Validation error', {closeButton: true, enableHtml: true})
            } else if (err.error.message){
              this.toastr.error(err.error.message, 'Error', {closeButton: true, enableHtml: true})
            }
          } else {
            console.log(err);

            if(err.url && !err.url.startsWith('https://api.openweathermap.org/')){
              if(err.status === 401) {
                this.toastr.error('The session has expired. Please sign in again');
                this.router.navigate(['/login']);
              } else if(!req.context.get(IGNORE_TOASTR_ERROR)) {
                this.toastr.error(err?.error?.message)
              }
            }

          }
        }

        throw err;
      })
    );
  }
}
