import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import {API_BASE_URL, API_VERSION} from "../../shared/shared.module";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  formControl = new UntypedFormControl(null, [Validators.required, Validators.email])
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    email: this.formControl,
  });
  disableButton = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private notif: ToastrService,
    private router: Router,
    @Inject(API_BASE_URL) protected baseUrl: string,
    @Inject(API_VERSION) protected version: string
  ) { }

  ngOnInit(): void {
  }

  send() {
    this.formControl.markAllAsTouched();
    if(this.formControl.valid) {
      this.disableButton.next(true)
      this.http.post<any>(
        `${this.baseUrl}${this.version}/password/remind`,
        {email: this.formControl.value}
      ).subscribe({
        next: (r) => {
          this.notif.success(r.message, this.translate.instant('Success'));
          this.disableButton.next(false);
          this.router.navigate(['/login']);
        },
        error: () => {
          this.disableButton.next(false);
        },
        complete: () => {
          this.disableButton.next(false);
        }
      })
    } else {
      this.notif.error(this.translate.instant('Validation error'))
    }
  }

}
