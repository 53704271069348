import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import {Observable} from "rxjs";
import {AccessService} from "../services/access.service";
import {AccessType} from "../models/access.type";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

export const AccessGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const access: AccessService = inject(AccessService);
  const translate: TranslateService = inject(TranslateService)
  const notif: ToastrService = inject(ToastrService);
  const router: Router = inject(Router);

    // console.log('Access guard', childRoute, state);
    const firstChild = state.url.split('/')[1];
    switch (firstChild) {
      case 'dashboard':
        return true;
      case 'payment':
        return access.can(AccessType.payments);
      case 'maintenance':
        return access.can(AccessType.maintenanceRequests);
      case 'message':
        return access.can(AccessType.communications);
      case 'amenity':
        return access.can(AccessType.amenities);
      case 'parcel':
        return access.can(AccessType.parcels);
      case 'document':
        return access.can(AccessType.documents);
      case 'insurance':
      case 'promotion':
      case 'profile':
      case 'upcoming-event':
      case 'survey':
        return true;
    }
    notif.error('', translate.instant('Access Denied'));
    return router.parseUrl('/dashboard');
  }