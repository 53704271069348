import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './error-not-found.component.html',
  styleUrls: ['./../error/error.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class ErrorNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
