import {Component, OnInit} from '@angular/core';
import {CustomisationService} from "../../shared/services/customisation.service";

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {

  constructor(
    public customisation: CustomisationService
  ) { }

  ngOnInit(): void {
  }

}
