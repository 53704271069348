<mat-card class="form-mat-card">
    <div class="form-mat-card-preparing" *ngIf="(preparing|async)">
        <span class="h5-bold">{{'Loading'|translate}}</span>
        <mat-spinner></mat-spinner>
    </div>
    
    <div class="title">{{'Tenant Login' | translate}}</div>

    <form class="pq-form" [formGroup]="formGroup" (keydown.enter)="submit()">
        <div class="label-and-mat-form-field margin-bottom">
            <label for="email">{{'Email' | translate}}</label>
            <mat-form-field class="full-width-field" subscriptSizing="dynamic">
                <input matInput placeholder="example@domain.com" formControlName="email" name="email" type="email">
                <mat-error>
                    <span *ngIf="formGroup.controls['email']?.errors?.required">{{'Email is required field' | translate}}</span>
                    <span *ngIf="formGroup.controls['email']?.errors?.email">{{'Not valid email' | translate}}</span>
                </mat-error>
            </mat-form-field>
        </div>
        
        <div class="label-and-mat-form-field">
            <label for="password">{{'Password' | translate}}</label>
            <mat-form-field class="full-width-field mat-form-field-button-suffix">
                <input matInput [type]="(showPassword | async) ? 'text' : 'password'" formControlName="password" name="password">
                <button matSuffix mat-icon-button tabindex="-1" (click)="toggleShowPassword()">
                    <mat-icon svgIcon="pq:eye" ></mat-icon>
                </button>
                <mat-error>
                    <span *ngIf="formGroup.controls['password']?.errors?.required">{{'Password is required field' | translate}}</span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="one-row one-row-space-around">
            <mat-checkbox formControlName="remember_me" color="primary" id="remember-me-checkbox">{{'Remember Me' | translate}}</mat-checkbox>
            <a routerLink="/reset" tabindex="-1"><label>{{'Forgot Password?' | translate}}</label></a>
        </div>
        
        <button
            mat-flat-button
            color="primary"
            class="full-width-field send-button"
            [disabled]="(requestSending | async)"
            (click)="submit()"
        ><mat-spinner diameter="20" *ngIf="(requestSending | async)" ></mat-spinner>{{'Login' | translate}}
        </button>
        <button
            mat-flat-button
            color="secondary"
            class="full-width-field send-button bio-auth-button"
            *ngIf="(biometryEnrolled | async) && (savedCredentials | async)"
            (click)="biometricAuth()"
        >{{'Biometric login' | translate}}</button>
    </form>
    <div class="notice-container">
        {{'Don’t have an account?' | translate}}
        <a class="notice-link" [routerLink]="'/property'"> {{'Register' | translate}}</a>
        <mat-divider vertical></mat-divider>
        <div class="notice-container--property-manager">
            {{'Are you a Property Manager?' | translate}}
            <a class="notice-link" [attr.href]="environment.managerApp"> {{'Manager Login' | translate}}</a>
        </div>
        <div class="lang-selector">
            <a (click)="locale.setLang('en')" [ngClass]="{'lang-active': locale.currentLang === 'en'}">{{'English' | translate}}</a> | 
            <a (click)="locale.setLang('fr')" [ngClass]="{'lang-active': locale.currentLang === 'fr'}">{{'French' | translate}}</a>
        </div>
    </div>
</mat-card>

<app-download-app></app-download-app>
