<app-toolbar
        [sidenavAdditionalServices]="sidenavAdditionalServices"
        [sidenavProfile]="sidenavProfile"
        *ngIf="(layout | async)?.toolbar"
></app-toolbar>
<mat-sidenav-container>
    <mat-sidenav
      role="navigation"
      [fixedInViewport]="true"
      [fixedTopGap]="mobileService.sidenavTopGap|async"
      [fixedBottomGap]="mobileService.sidenavBottomGap|async"
      #sidenavAdditionalServices
    >
        <app-sidenav-additional-services [matSidenav]="sidenavAdditionalServices"></app-sidenav-additional-services>
<!--        <div class="sidenav-container">-->

<!--        </div>-->
    </mat-sidenav>
    <mat-sidenav #sidenavProfile position="end" role="navigation" class="pq-sidenav sidenav-container-profile">
        <app-sidenav-profile [matSidenav]="sidenavProfile"></app-sidenav-profile>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="{'mat-sidenav-content-white': (layoutService.matSideNavContentWhite | async)}">
        <main class="main-container">
            <div class="router-container" [ngClass]="{'router-container__drop-padding-840': (layoutService.routerContainerDropPadding840 | async)}">
                <router-outlet></router-outlet>
            </div>
        </main>
        <app-footer *ngIf="(layout | async)?.footer"></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
