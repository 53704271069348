<div>
    <h1 mat-dialog-title class="align-center">{{"Can't find your property?" | translate}}</h1>
    <div mat-dialog-content class="dialog-content notice-container">
        <div>
            &Tab;{{"Your building is not signed up yet, or your property management requires you to enter your unique security code on the previous page" | translate}} 
        </div>
        <div class="notice-container">
            &Tab;{{"Please contact your manager to receive your unique login code or" | translate}} 
            <a href='https://payquad.com/support-centre/' class="notice-link">{{'click here' | translate}}</a> 
            {{'for support.' | translate}}
        </div>
    </div>
</div >