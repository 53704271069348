<div class="sidenav-header">
    <img class="avatar" [src]="avatar|async"/>
    <p class="h7 user-name">{{(user | async)?.first_name}} {{(user | async)?.last_name}}</p>
    <p mat-menu-item class="mat-subtitle-2 unit-name" [matMenuTriggerFor]="units" *ngIf="((accounts|async)?.length || 0)>1">
        <span class="unit-name_title">
            <span>{{(currentAccount|async)?.title}} </span>
            <span> {{(currentAccount|async)?.title_line_2}}</span>
        </span>

        <mat-icon class="unit-name_expand" [inline]="true" *ngIf="((accounts|async)?.length || 0) > 1">expand_more</mat-icon>
    </p>
    <p class="mat-subtitle-2 unit-name" *ngIf="(accounts|async)?.length === 1">
        <span class="unit-name_title">
            <span>{{(currentAccount|async)?.title}}</span>
            <span>{{(currentAccount|async)?.title_line_2}}</span>
        </span>
    </p>
</div>
<div class="divider-container">
    <mat-divider></mat-divider>
</div>

<mat-nav-list>
    <a mat-list-item routerLink="/profile">
          <span class="mat-subtitle-2">
            <span class="mat-icon-container mat-icon-container-no-background">
              <mat-icon svgIcon="pq:user_profile"></mat-icon>
            </span>
              {{'My profile' | translate}}
          </span>
    </a>
    <a mat-list-item href="https://payquad.com/support-centre/" target="_blank" appOpenInAppBrowser>
          <span class="mat-subtitle-2">
            <span class="mat-icon-container mat-icon-container-no-background">
              <mat-icon svgIcon="pq:home_house_message_chat"></mat-icon>
            </span>
              {{'Help Center' | translate}}
          </span>
    </a>
<!--    <a mat-list-item>-->
<!--          <span class="mat-body-2">-->
<!--            <div class="mat-icon-container mat-icon-container-no-background">-->
<!--              <mat-icon svgIcon="pq:settings_round"></mat-icon>-->
<!--            </div>-->
<!--              {{'Settings & Preferences' | translate}}-->
<!--          </span>-->
<!--    </a>-->
    <ng-container *ngIf="customization.config|async; let c" >
        <a mat-list-item *ngIf="c.website" [href]="c.website" target="_blank" appOpenInAppBrowser>
          <span class="mat-subtitle-2">
            <span class="mat-icon-container mat-icon-container-no-background">
              <mat-icon svgIcon="pq:earth_cursor_select"></mat-icon>
            </span>
              {{c.name}} Website
          </span>
        </a>
    </ng-container>
    <a mat-list-item (click)="signOut()">
          <span class="mat-subtitle-2">
            <span class="mat-icon-container mat-icon-container-no-background">
              <mat-icon svgIcon="pq:login_logout_arrow"></mat-icon>
            </span>
              {{'Sign Out' | translate}}
          </span>
    </a>
</mat-nav-list>

<mat-menu #units="matMenu" class="pq-menu pq-menu-center main-navigation-menu sidenav-profile-unit-menu" xPosition="before">
    <div class="triangle"></div>
    <ng-container *ngFor="let a of (accounts|async)">
        <button mat-menu-item (click)="switchUser(a.id)" *ngIf="a.id !== (user|async)?.id && a.title">
            <span class="mat-icon-container">
              <mat-icon matPrefix svgIcon="pq:modern-building" color="primary"></mat-icon>
            </span>
            <span>{{a.title}}<br> {{a.title_line_2}}</span>
        </button>
        <a (click)="goLeasingPortal()" mat-menu-item *ngIf="a.permissions==='applicant'">
            <span>Applicant: {{a.first_name}} {{a.last_name}}</span>
            <mat-icon matPrefix svgIcon="pq:documents_file_text" color="primary"></mat-icon>
        </a>
    </ng-container>
</mat-menu>
