import {Inject, Injectable} from '@angular/core';
import {BaseRestService} from "../../shared/rest/base-rest.service";
import {MessageModel} from "./resources/message.model";
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {API_BASE_URL, API_VERSION} from "../../shared/shared.module";
import {MessageCreateModel} from "./resources/message-create.model";
import {MessageUpdateModel} from "./resources/message-update.model";
import {distinctUntilChanged, withLatestFrom} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {UserService} from "../../shared/services/user.service";

@Injectable({
  providedIn: 'root'
})
export class MessageService extends BaseRestService<MessageModel, MessageCreateModel, MessageModel, MessageUpdateModel, MessageModel>{

  resourceName = 'messages';

  markAsReadIds = new BehaviorSubject<number[]>([]);

  protected constructor(
    http: HttpClient,
    @Inject(API_BASE_URL) baseUrl: string,
    @Inject(API_VERSION) version: string,
    private translateService: TranslateService,
    private notif: ToastrService,
    private router: Router,
    private userService: UserService,
  ) {
    super(http, baseUrl, version);

    this.userService.unread.pipe(
      distinctUntilChanged()
    ).subscribe(() => this.dataChanged.emit());

  }

  markAsRead(id: number | number[]) {
    if (Array.isArray(id)){
      this.http.post(
        this.baseUrl + this.version + '/' + this.resourceName + '/read',
        {
          ids: id
        }
      )
        .pipe(withLatestFrom(this.markAsReadIds))
        .subscribe(
        ([r, ids]: [any, number[]]) => {
          console.log('Mark as read ID:' + id, r);
          this.markAsReadIds.next([...ids, ...id]);
          this.dataChanged.emit(true);
          // this.userService.unread.next(this.userService.unread.getValue() - id.length);
        }
      );
    } else {
      this.updatePatch(id, {is_read: true}, true).pipe(withLatestFrom(this.markAsReadIds)).subscribe(
        ([r, ids]: [any, number[]]) => {
          console.log('Mark as read ID:' + id, r);
          this.markAsReadIds.next([...ids, id])
          // this.userService.unread.next(this.userService.unread.getValue() - 1);
        }
      )
    }
  }

  markAsArchive(id: number | number[]) {
    if (Array.isArray(id)){
      this.http.post(
        this.baseUrl + this.version + '/' + this.resourceName + '/archive',
        {
          ids: id
        }
      )
        .pipe(withLatestFrom(this.markAsReadIds))
        .subscribe(
          ([r, ids]: [any, number[]]) => {
            this.dataChanged.emit(true);
            this.notif.success(
              this.translateService.instant('Moved to archive'),
              this.translateService.instant('Success')
            );
          }
        );
    } else {
      this.updatePatch(id, {archive: true}).subscribe(
        (r) => {
          this.notif.success(
            this.translateService.instant('Moved to archive'),
            this.translateService.instant('Success')
          );
        }
      )
    }
  }

  markAsUnarchive(id: number | number[]) {
    if (Array.isArray(id)){
      this.http.post(
        this.baseUrl + this.version + '/' + this.resourceName + '/unarchive',
        {
          ids: id
        }
      )
        .pipe(withLatestFrom(this.markAsReadIds))
        .subscribe(
          ([r, ids]: [any, number[]]) => {
            this.dataChanged.emit(true);
            this.notif.success(
              this.translateService.instant('Unarchived'),
              this.translateService.instant('Success')
            );
          }
        );
    } else {
      this.updatePatch(id, {archive: false}).subscribe(
        (r) => {
          this.notif.success(
            this.translateService.instant('Unarchived'),
            this.translateService.instant('Success')
          );
        }
      )
    }
  }

  markAsDelete(id: number | number[], navigateTop = false) {
    if (Array.isArray(id)){
      this.http.post(
        this.baseUrl + this.version + '/' + this.resourceName + '/delete',
        {
          ids: id
        }
      )
        .pipe(withLatestFrom(this.markAsReadIds))
        .subscribe(
          ([r, ids]: [any, number[]]) => {
            this.dataChanged.emit(true);
            this.notif.success(
              this.translateService.instant('Deleted'),
              this.translateService.instant('Success')
            );
          }
        );
    } else {
      this.delete(id).subscribe(
        r => {
          this.notif.success(
            this.translateService.instant('Deleted'),
            this.translateService.instant('Success')
          );
          if(navigateTop) {
            this.router.navigate([
              this.router.url.slice(0, this.router.url.lastIndexOf('/'))
            ]);
          }
        }
      )
    }
  }

}
