import {Inject, Injectable} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {filter, switchMap, tap} from "rxjs/operators";
import { BehaviorSubject, combineLatest, of, lastValueFrom } from "rxjs";
import {API_BASE_URL, API_VERSION} from "../shared.module";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {UserService} from "./user.service";
import {DashboardMessageService} from "../../tenant/dashboard/dashboard-message.service";
import { Capacitor } from "@capacitor/core";
import { App } from '@capacitor/app';
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isMobileApp = Capacitor.isNativePlatform()
  private _token: BehaviorSubject<string|null> = new BehaviorSubject<string|null>(localStorage.getItem('token'));
  private _csrf:  BehaviorSubject<string|null> = new BehaviorSubject<string|null>(null);

  public get isLoggedIn() {
    return combineLatest([
      this._token,
      this.userService.meError
    ])
    .pipe(
      filter(([token, error]) => error !== null),
      switchMap(([token, error]) => {
        return token && !error ? of(true) : of(false);
      })
    )
  }

  constructor(
    private userService: UserService,
    private http: HttpClient,
    @Inject(API_BASE_URL) public baseUrl: string,
    @Inject(API_VERSION) public version: string,
    private router: Router,
    private dashboardMessageService: DashboardMessageService,
    private storageService: StorageService
  ) {
    const xsrf = document.cookie.split(';')
      .find(
        (item) => item.trim().startsWith('XSRF-TOKEN=')
      );
    if (xsrf) {
      this._csrf.next(xsrf.replace('XSRF-TOKEN=', ''))
    }
    if(this.isMobileApp) {
      this.storageService.getItem('token').then(response => {
        if (response) {
          this._token.next(response)
        }
      })
    }
  }

  getToken() {
    return this._token;
  }

  getTokenUrlParam() {
    const token = this._token.getValue()
    if(!token || !this.isMobileApp) {
      return ''
    }

    return `?token=${encodeURI(token)}`
  }

  async setToken(token: string|null) {
    if(token) {
      this.storageService.setItem('token', token)
    } else {
      this.storageService.removeItem('token')
    }
    this._token.next(token);
  }

  getCsrfToken() {
    return this._csrf;
  }

  csrf() {
    return this.http.get(
      this.baseUrl + this.version + '/csrf-cookie',
      {withCredentials: true, observe:"response"}
    ).pipe(
      tap(r => {

        const xsrf = document.cookie.split(';')
          .find(item => item.trim().startsWith('XSRF-TOKEN='));
        if (xsrf) {
          const xsrfValue = decodeURIComponent(xsrf).replace('XSRF-TOKEN=','')
          this._csrf.next(xsrfValue)
        }
      })
    );
  }

  login(email:string, password:string ) {
    const csrfToken = this.getCsrfToken().getValue();
    let headers = new HttpHeaders();
    if(csrfToken) {
      headers = headers.set("X-XSRF-TOKEN", csrfToken)
    }

    return this.http.post<any>(
      this.baseUrl + this.version + '/login',
      {email, password, app_name: environment.appName, is_mobile_app: this.isMobileApp},
      {
        headers: headers,
        withCredentials: environment.useCsrfToken
      }
    ).pipe(
      tap(d => this.setToken(d.access_token)),
    );
  }

  logout() {
    this.http.post(`${this.baseUrl + this.version}/logout?token=${this._token.getValue()}`, {} ).subscribe(
      r => {
        window.rudderanalytics.reset(true);
        this.setToken(null);
        this.userService.onboardingComplete.next(undefined);
        this.userService.user.next(undefined);
        this.dashboardMessageService.clearRead();
        window.location.href = '/login';
      }
    )
  }

  switch(id: number) {
    this.http.post<any>(this.baseUrl + this.version + `/login/${id}`, {}).subscribe(
      r => {
        window.rudderanalytics.reset(true);
        this.setToken(r.access_token);
        this.userService.onboardingComplete.next(undefined);
        this.userService.user.next(undefined);
        this.dashboardMessageService.clearRead();
        window.location.href = '/dashboard';
      }
    )
  }

  register(data: any) {
    return this.http.post<any>(
      this.baseUrl + this.version + '/register',
      data
    );
  }

  resetPassword(data: any) {
    return this.http.post<any>(
      this.baseUrl + this.version + '/password/reset',
      data
    );
  }

  async checkAppVersion() {
    const appInfo = await App.getInfo()
    const platform = Capacitor.getPlatform()
    const res = await this.http.get(this.baseUrl + `v1/mobile-apps/check?app-id=${appInfo.id}&version=${appInfo.version}&platform=${platform}`)
    return lastValueFrom(res)
  }
}
