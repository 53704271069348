import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../shared/services/auth.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { BehaviorSubject, from } from "rxjs";
import { environment } from "../../../environments/environment";
import { UserService } from "../../shared/services/user.service";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { Capacitor } from '@capacitor/core';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Dialog } from '@capacitor/dialog';
import { LocalizationService } from 'src/app/shared/services/localization.service';
import { BiometricService } from '../resources/biometric.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {
  private isMobileApp = Capacitor.isNativePlatform()

  public formGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    remember_me: new FormControl<boolean>(false, [])
  });

  showPassword = new BehaviorSubject(false);

  requestSending = new BehaviorSubject(false);

  preparing = new BehaviorSubject<boolean>(false);
  environment = environment;

  /** biometry enrolled by user */
  biometryEnrolled = this.biometricService.biometryEnrolled;

  /** password saved for this user */
  savedCredentials = from(this.biometricService.getCredentials())

  constructor(
    private authService: AuthService,
    private notif: ToastrService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private storageService: StorageService,
    public locale: LocalizationService,
    private biometricService: BiometricService,
  ) {
    if (environment.useCsrfToken) {
      this.authService.csrf().subscribe();
    }

    this.storageService.getItem('remember_me').then(response => {
      this.formGroup.patchValue({
        email: response,
        remember_me: true
      })
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // Check if the 'login-as' parameter is present and set to 'true'
      console.log('params', params)
      if (params['login-as'] === 'true') {
        this.loginAs(params['token']);
      }
    });
    this.dialog.closeAll();  // close dialogs that were open before redirect
  }

  ngOnDestroy(): void {
    this.notif.clear();
  }

  toggleShowPassword() {
    this.showPassword.next(!this.showPassword.getValue());
  }

  /**
   * Handles validation and other form logic before submitting login request.
   */
  submit() {
    if (this.formGroup.valid) {
      this.requestSending.next(true);
      if (this.formGroup.get('remember_me')?.value) {
        this.storageService.setItem('remember_me', this.formGroup.get('email')?.value || '')
      } else {
        this.storageService.removeItem('remember_me')
      }
      this._submit(this.formGroup.get('email')?.value!, this.formGroup.get('password')?.value!)
    } else {
      this.formGroup.markAllAsTouched()
    }
  }

  /**
   * Submits login request to server. Used as a helper for <submit>.
   */
  private _submit(email: string, password: string) {
    this.authService
      .login(email, password)
      .subscribe({
        next: async r => {
          if (r.success === false && r.errors?.length) {
            this.notif.error(r.errors[0], this.translate.instant('Error'));
            this.formGroup.controls['email'].markAsUntouched();
            this.formGroup.controls['password'].markAsUntouched();
            return;
          }
          this.preparing.next(true);
          this.userService.callMeUpdate();
          this.userService.me().pipe(take(1)).subscribe(
            async u => {
              window.rudderanalytics.identify(u.id, {
                email: u.email,
                first_name: u.first_name,
                last_name: u.last_name,
                phone: u.phone_number
              });

              // set the credentials to the most updated password
              if (this.isMobileApp && this.biometryEnrolled.getValue()) {
                this.biometricService.setCredentials(email, password);
              } else if (
                this.isMobileApp &&
                this.biometricService.biometryAvailable.getValue() &&
                this.biometryEnrolled.getValue() === null
              ) {
                const bio_onboarding = await this.biometricService.enrollBiometric();
                this.biometricService.deleteCredentials();

                if (!bio_onboarding) {
                  this.requestSending.next(false);  // stop loading spinner
                  this.preparing.next(false);  // stop loading spinner
                } else {
                  this.biometricService.setCredentials(email, password);
                }
              }

              if (this.route.snapshot.queryParams['returnUrl']) {
                const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                this.router.navigateByUrl(returnUrl);
              } else {
                this.router.navigate(['/dashboard']);
              }
            }
          );
        },
        error: async (e) => {
          this.formGroup.markAsUntouched();
          this.requestSending.next(false);
          if (e.status == 303) {
            this.notif.warning(this.translate.instant('Redirecting to old portal ("https://portal.payquad.com/#/login") momentarily...'), e.error.message, {
              disableTimeOut: 'timeOut',
            });
            setTimeout(
              () => {
                window.location.href = environment.apiBase + `/#/login`;
              },
              5000
            );
          }
        },
        complete: () => {
          this.requestSending.next(false)
        }
      });
  }

  /**
   * Log in using biometric authentication. Called when user clicks on "Biometric login" in the login screen.
   */
  async biometricAuth() {
    const verified = await this.biometricService.authenticateBiometric();

    if (!verified) {
      await Dialog.alert({
        title: this.translate.instant("Biometric verification failed"),
        message: this.translate.instant("Please sign in using your password"),
      });
      return;
    }

    try {
      const credentials = await this.biometricService.getCredentials();

      if (credentials) {
        this._submit(credentials.username, credentials.password);
      } else {
        await Dialog.alert({
          title: this.translate.instant("Credentials not found"),
          message: this.translate.instant("Please sign in using your password"),
        });
        console.log(`There is no credentials saved`);
      }
    } catch (exception) {
      await Dialog.alert({
        title: this.translate.instant("Biometric authentication failed"),
        message: this.translate.instant("Please enroll in or enable biometric authentication"),
      });
      return;
    }
  }

  loginAs(access_token: string) {
    // http://localhost:4200/login?login-as=true
    this.requestSending.next(true);
    this.formGroup.markAsUntouched()

    this.notif.info(this.translate.instant('Loading... Please wait'), this.translate.instant('Login as Tenant'));
    this.authService.setToken(access_token)

    this.userService.callMeUpdate();
    this.userService.me().pipe(
      take(1)
    ).subscribe({
      next: u => {
        this.router.navigate(['/dashboard']);
      },
      error: error => {
        this.notif.error(this.translate.instant('Error'), this.translate.instant('Error Logging in as Tenant'));
        this.requestSending.next(false);
      }
    });
  }
}
