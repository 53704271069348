import { PropertyModel } from "src/app/shared/models/property.model";
import { UnitModel } from "../resources/unit.model";

export class InviteMetaModel {
  first_name = '';
  last_name = '';
  email = '';
  property?:      PropertyModel;
  unit?:          UnitModel;
  address?:       string;
  city?:          string;
  country?:       string;
  postal?:        string;
  province?:      string;

  constructor(public code: string) {
  }
}
