import {PropertyModel} from "../../shared/models/property.model";

export class RegisterModel {
  public email?:          string;
  public first_name?:     string;
  public last_name?:      string;
  public password?:       string;
  public password_confirmation?: string;
  public terms?:          boolean;
  public userType?:       string;
  public property_id?:    number;
  public property?:       PropertyModel;
  public unit?:           string;
  public security_code?:  string;
  public invite_code?:    string;
}
