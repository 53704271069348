import {ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot, UrlTree} from "@angular/router";
import {ComponentPendingChanges} from "../interfaces/component-pending-changes.interface";
import {Observable} from "rxjs";

export const PendingChangesGuard: CanDeactivateFn<ComponentPendingChanges> = (
  component: ComponentPendingChanges,
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  nextState?: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return component.canDeactivate();
};
