<div class="prompt-content">
    <img class="prompt-content--logo" src="assets/images/logo.png" alt="Site Logo" />
    <h1 mat-dialog-title>{{ 'The app needs to be updated' | translate }}</h1>
    <div mat-dialog-content class="dialog-content notice-container">
        <div class="notice-container">
            <p *ngIf="platform === 'ios'">{{ 'Please download the newest version from the App Store.' | translate }}</p>
            <p *ngIf="platform === 'android'">{{ 'Please download the newest version from Google Play.' | translate }}
            </p>
        </div>
        <div>
            <a (click)="openStoreLink()" class="prompt-content--store-image">
                <img src="assets/images/app-ios.png" *ngIf="platform === 'ios'" alt="Download on the App Store">
                <img src="assets/images/app-android.png" *ngIf="platform === 'android'" alt="Get it on Google Play">
            </a>
        </div>
    </div>
</div>