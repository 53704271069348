import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {AuthService} from "../../shared/services/auth.service";
import {CustomisationService} from "../../shared/services/customisation.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit {
  layout: Observable<{toolbar: boolean, footer: boolean}> = new BehaviorSubject({toolbar: true, footer: true});

  constructor(
    public authService: AuthService,
    public customization: CustomisationService,
    public router: Router
  ) {
    // this.router.url
    console.log(this.router.url);
  }

  ngOnInit(): void {
  }

}
