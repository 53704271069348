import {BaseRestService} from "../../../shared/rest/base-rest.service";
import {PaymentHistoryModel} from "./resources/payment-history.model";
import { HttpClient } from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {API_BASE_URL, API_VERSION} from "../../../shared/shared.module";

@Injectable()
export class PaymentHistoryService extends BaseRestService<PaymentHistoryModel, PaymentHistoryModel, PaymentHistoryModel, PaymentHistoryModel, PaymentHistoryModel> {
  protected resourceName = 'payments/payment-history';
  constructor(
    http: HttpClient,
    @Inject(API_BASE_URL) baseUrl: string,
    @Inject(API_VERSION) version: string
  ) {
    super(http, baseUrl, version);
  }

}