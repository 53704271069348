import {Injectable} from "@angular/core";
import {UserService} from "./user.service";
import {Observable, of} from "rxjs";
import {UserModel} from "../models/user.model";
import {AccessType} from "../models/access.type";
import {shareReplay, switchMap} from "rxjs/operators";
import {Router, UrlTree} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AccessService {
  private user: Observable<UserModel>;

  constructor(
    private userService: UserService,
    private router: Router
  ) {
    this.user = this.userService.me().pipe(shareReplay());
  }

  can(access: AccessType): Observable<boolean|UrlTree> {
    return this.user.pipe(
      switchMap(u => {
        if(!u?.approved) {
          return of(this.router.parseUrl('onboarding/pending'))
        }
        return of(
          u?.user_permissions?.[access]
          || (access === AccessType.insurance && u?.insurance_nav)
          || (access === AccessType.promotions && u?.rewards)
          || [AccessType.dashboard].some(i => i === access)
          || false
        );
      })
    )
  }

  isApproved(): Observable<boolean> {
    return this.user.pipe(
      switchMap(u => of(u?.approved || false))
    )
  }
}
