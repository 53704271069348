import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {MobileService} from "../../shared/services/mobile.service";

@Component({
  selector: 'app-suppress-mobile-mat-card-layout',
  templateUrl: './suppress-mobile-mat-card-layout.component.html',
  styleUrls: ['./suppress-mobile-mat-card-layout.component.scss']
})
export class SuppressMobileMatCardLayoutComponent implements OnInit {

  layout: Observable<{toolbar: boolean, footer: boolean}> = new BehaviorSubject({toolbar: true, footer: true});

  constructor(
    public mobileService: MobileService
  ) { }

  ngOnInit(): void {
  }

}
