import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
// import * as Sentry from "@sentry/angular-ivy";
// import { BrowserTracing } from "@sentry/tracing";
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
  // Sentry.init({
  //   dsn: "https://90fa251841d34b358c4b01ba5b2abe95@o541320.ingest.sentry.io/5660436",
  //   replaysSessionSampleRate: 0.1,

  //   // If the entire session is not sampled, use the below sample rate to sample
  //   // sessions when an error occurs.
  //   replaysOnErrorSampleRate: 0.5,
  //   integrations: [
  //     new BrowserTracing({
  //       tracePropagationTargets: ["localhost", /.*\.?payquad\.com/],
  //       routingInstrumentation: Sentry.routingInstrumentation,
  //     }),
  //     new Sentry.Replay({
  //       // Additional SDK configuration goes in here, for example:
  //       maskAllText: true,
  //       blockAllMedia: true,
  //       mutationLimit: 7000,
  //     }),
  //   ],

  //   tracesSampleRate: 0.01,
  // });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
