<footer *ngIf="customization.config|async; let c">
    <div class="footer-container mat-subtitle-2" *ngIf="layout === 'default'">
        <div class="contacts footer-group">
            <p class="h5">{{c.name}}</p>
<!--            <p>35 Mandalay Road 13–37 Mandalay <br/> Towers Singapore 308215</p>-->
            <p>{{c.address}}</p>
            <p class="contacts-info">
                <span *ngIf="c.phone_number">
                    <mat-icon svgIcon="pq:phone_call"></mat-icon>
                    <span>{{c.phone_number}}</span>
                </span>
                <mat-divider [vertical]="true" *ngIf="c.phone_number && c.email"></mat-divider>
                <span *ngIf="c.email">
                    <mat-icon svgIcon="pq:letter"></mat-icon>
                    <span>{{c.email}}</span>
                </span>
            </p>
            <p>
                <a *ngIf="c.facebook; let link" [href]="link">
                    <img src="styles/img/social/facebook.svg" alt="Facebook"/>
                </a>
<!--                <a *ngIf="c.facebook; let link" [href]="link">-->
<!--                    <img src="styles/img/social/linkedin.svg" alt="LinkedIn"/>-->
<!--                </a>-->
                <a *ngIf="c.twitter; let link" [href]="link">
                    <img src="styles/img/social/twitter.svg" alt="Twitter"/>
                </a>
<!--                <a *ngIf="c.facebook; let link" [href]="link">-->
<!--                    <img src="styles/img/social/youtube.svg" alt="Youtube"/>-->
<!--                </a>-->
            </p>
        </div>
        <div class="poweredby footer-group">
            <p>{{'Powered by' | translate}}:</p>
            <p><img class="logo" src="styles/img/logo-footer.svg"></p>
            <p>
                <mat-divider></mat-divider>
            </p>
            <p>
                <mat-icon svgIcon="pq:phone_call"></mat-icon>
                <span>1 888 385 9037</span>
            </p>
            <p>
                <mat-icon svgIcon="pq:letter"></mat-icon>
                <span>info&#64;payquad.com</span>
            </p>
        </div>
        <div class="lang-selector">
            <a (click)="locale.setLang('en')" [ngClass]="{'lang-active': locale.currentLang === 'en'}">{{'English' | translate}}</a> | 
            <a (click)="locale.setLang('fr')" [ngClass]="{'lang-active': locale.currentLang === 'fr'}">{{'French' | translate}}</a>
        </div>
        <mat-divider class="wide-divider"></mat-divider>
        <div class="footer-group">
            <p>{{'All rights Reserved' | translate}} {{now | date:'YYYY'}}</p>
        </div>
        <div class="term-of-use footer-group">
            <p>
                <a href="/terms-of-service">{{'Terms of Service'|translate}}</a>
                <a href="/privacy-policy">{{'Privacy Policy'|translate}}</a>
                <mat-divider [vertical]="true"></mat-divider>
                <img src="styles/img/geotrust.png">
            </p>
        </div>
    </div>
    <div class="footer-container tiny mat-subtitle-2" *ngIf="layout === 'tiny'">
        <div class="footer-group">
            <p>{{'All rights Reserved' | translate}} {{now | date:'YYYY'}}</p>
        </div>
        <div class="term-of-use footer-group">
            <p>
                <a href="/terms-of-service">{{'Terms of Service'|translate}}</a>
                <a href="/privacy-policy">{{'Privacy Policy'|translate}}</a>
                <mat-divider [vertical]="true"></mat-divider>
                <img src="styles/img/geotrust.png" [alt]="'Secured by GeoTrust' | translate">
            </p>
        </div>
    </div>
</footer>
