import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { InviteMetaModel } from "./invite-meta.model";
import { EmailInviteService } from "./email-invite.service";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, map, mergeMap, take } from "rxjs/operators";

export const EmailInviteResolver: ResolveFn<InviteMetaModel> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  inviteService: EmailInviteService = inject(EmailInviteService),
  router: Router = inject(Router)
): Observable<InviteMetaModel> => {
  const code = route.queryParamMap.get("invite-code");
  if (!code) {
    router.navigate(["/404"]);
    return EMPTY;
  }
  return inviteService.read(code).pipe(
    take(1),
    catchError(() => {
      router.navigate(["/404"]);
      return EMPTY;
    }),
    map((r) => r.body),
    mergeMap((meta) => {
      if (meta) {
        return of(meta);
      } else {
        // id not found
        router.navigate(["/404"]);
        return EMPTY;
      }
    })
  );
};
