import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {RegisterModel} from "./register-form/register.model";
import {TenantModel} from "../shared/models/tenant.model";
import {PropertyModel} from "../shared/models/property.model";
import {environment} from "../../environments/environment";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {switchMap, take} from "rxjs/operators";

@Injectable()
export class RegistrationService {

  constructor(private http: HttpClient) {
  }

  registrationFormData = new BehaviorSubject<RegisterModel>(new RegisterModel());
  private property: PropertyModel|undefined;

  //first step
  setRegistrationFormData(data: RegisterModel) {
    this.registrationFormData.next(
      Object.assign(
        this.registrationFormData.getValue(),
        data,
      )
    );
  }

  clearRegistrationFormData() {
    this.registrationFormData.next(new RegisterModel());
  }

  //send
  send() {
    return this.registrationFormData.pipe(
      take(1),
      switchMap(d => {
        return this.http.post(
          environment.apiBaseUrl + environment.apiVersion + '/register',
          {...d},
          {observe: "response", withCredentials: true},
        )
      })
    );
  }

  validateEmail(email: string): Observable<any> {
    return this.http.post(
      environment.apiBaseUrl + environment.apiVersion + '/register/validate-email',
      {email: email},
      {observe: "response", withCredentials: true},
    );
  }

  validateCode(code: string): Observable<HttpResponse<TenantModel>> {
    return this.http.post(
      environment.apiBaseUrl + environment.apiVersion + '/register/validate-code',
      {security_code: code},
      {observe: "response", withCredentials: true},
    );
  }

}