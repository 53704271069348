import {RestFullInterface} from "./interfaces/rest-full.interface";
import {BehaviorSubject, Observable} from "rxjs";
import { HttpClient, HttpContext, HttpParams, HttpResponse } from "@angular/common/http";
import {EventEmitter, Inject} from "@angular/core";
import {API_BASE_URL, API_VERSION} from "../shared.module";
import {map, shareReplay, tap} from "rxjs/operators";
import {IGNORE_TOASTR_ERROR} from "../error.interceptor";
import { v4 as uuidv4 } from 'uuid';

export abstract class BaseRestService<T, C, R, U, D> implements RestFullInterface<T, C, R, U, D> {

  protected abstract resourceName: string;

  public dataChanged = new EventEmitter();

  protected constructor(
    protected http: HttpClient,
    @Inject(API_BASE_URL) protected baseUrl: string,
    @Inject(API_VERSION) protected version: string
  ) {
  }

  list(httpParams?: HttpParams, loading?: BehaviorSubject<boolean>, resourceName?: string): Observable<HttpResponse<T[]>> {
    return this.http.get<T[]>(
      this.baseUrl + this.version + '/' + (resourceName ? resourceName : this.resourceName),
      {
        params: httpParams,
        observe: "response"
      }
    ).pipe(
      tap(() => loading?.next(false)),
      shareReplay()
    );
  }

  create(item: C, resourceName?: string): Observable<R> {
    const idempotencyKey = uuidv4();
    const headers = {
      'Idempotency-Key': idempotencyKey
    };

    return this.http.post<R>(
      this.baseUrl + this.version + '/' + (resourceName ? resourceName : this.resourceName),
      item,
      {
        observe: "response",
        headers: headers,
        context: new HttpContext().set(IGNORE_TOASTR_ERROR, true)
      }
    ).pipe(
      tap(() => this.dataChanged.emit()),
      map(r => ((r.status === 200 || r.status === 201) && r.body ? r.body : null) as R)
    );
  }

  update(id: number|string, item: U, sendAsPost = false): Observable<U> {
    const idempotencyKey = uuidv4();
    const headers = {
      'Idempotency-Key': idempotencyKey
    };

    if(sendAsPost) {
      return this.http.post<U>(
        this.baseUrl + this.version + '/' + this.resourceName + '/' + id,
        item,
        {observe: "response"}
      ).pipe(
        tap(() => this.dataChanged.emit()),
        map(r => r.body as U)
      )
    }
    return this.http.put<U>(
      this.baseUrl + this.version + '/' + this.resourceName + '/' + id,
      item,
      {observe: "response", headers: headers}
    ).pipe(
      tap(() => this.dataChanged.emit()),
      map(r => r.body as U)
    )
  }

  updatePatch(id: number|string, item: U, skipDataChangeEmit = false, resourceName?: string): Observable<U> {
    const idempotencyKey = uuidv4();
    const headers = {
      'Idempotency-Key': idempotencyKey
    };

    return this.http.patch<U>(
      this.baseUrl + this.version + '/' + (resourceName ? resourceName : this.resourceName) + '/' + id,
      item,
      {observe: "response", headers: headers}
    ).pipe(
      tap(() => skipDataChangeEmit ? null : this.dataChanged.emit()),
      map(r => r.body as U)
    )
  }

  read(id: number|string, ignoreToastrError = false): Observable<HttpResponse<R>> {
    return this.http.get<R>(
      this.baseUrl + this.version + '/' + this.resourceName + '/' + id,
      {
        observe: "response",
        context: (new HttpContext()).set(IGNORE_TOASTR_ERROR, ignoreToastrError)
      }
    );
  }

  delete(id: number|string, resourceName?: string): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.baseUrl + this.version + '/' + (resourceName ? resourceName : this.resourceName) + '/' + id,
      {
        observe: "response"
      }
    ).pipe(
      tap((r) => r.status === 204 ? this.dataChanged.emit() : null),
    );
  }


}
