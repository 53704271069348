<mat-card   class="form-mat-card">
    <div class="illustration-container">
        <img src="assets/images/illustrations/login-code.png" />
        <div class="illustration-description" *ngIf="!public_registration">
            We’ve sent you a unique security code in the mail.
            Please enter it below to get started.
            <br> If you have not received a security code, contact your manager.
        </div>
        <div class="illustration-description" *ngIf="public_registration">
            If you received a unique security code in the mail,
            please enter it below to get started.
            If you do not have a code, simply click "Skip"
        </div>
    </div>
    <form class="code-container pq-form" autocomplete="off" [formGroup]="formGroup">
        <ng-container formArrayName="code">
            <mat-form-field>
                <input
                        matInput
                        formControlName="0"
                        autocomplete="off"
                        type="search"
                        [maxLength]="1"
                        (keydown)="onKeydown($event)"
                        (focus)="onFocus($event)"
                        (paste)="onPaste($event)"
                        #codeInput
                />
            </mat-form-field>
            <mat-form-field>
                <input
                        matInput
                        formControlName="1"
                        autocomplete="off"
                        type="search"
                        [maxLength]="1"
                        (keydown)="onKeydown($event)"
                        (focus)="onFocus($event)"
                        #codeInput
                />
            </mat-form-field>
            <mat-form-field>
                <input
                        matInput
                        formControlName="2"
                        autocomplete="off"
                        type="search"
                        [maxLength]="1"
                        (keydown)="onKeydown($event)"
                        (focus)="onFocus($event)"
                        #codeInput
                />
            </mat-form-field>
            <mat-form-field>
                <input
                        matInput
                        formControlName="3"
                        autocomplete="off"
                        type="search"
                        [maxLength]="1"
                        (keydown)="onKeydown($event)"
                        (focus)="onFocus($event)"
                        #codeInput
                />
            </mat-form-field>
            <mat-form-field>
                <input
                        matInput
                        formControlName="4"
                        autocomplete="off"
                        type="search"
                        [maxLength]="1"
                        (keydown)="onKeydown($event)"
                        (focus)="onFocus($event)"
                        #codeInput
                />
            </mat-form-field>
        </ng-container>
    </form>
    <div
            class="one-row one-row-center one-row-tiny-gap accent-font-success-1"
            *ngIf="valid | async"
    >
        <mat-icon svgIcon="pq:protected"></mat-icon>
        <span class="h8-bold">{{'Verified' | translate}}</span>
    </div>
    <button
            mat-flat-button
            color="primary"
            class="send-button"
            [disabled]="!(valid | async) && !public_registration"
            (click)="send()"
    >
        <span>{{(public_registration && !(valid | async)) ? 'Skip' : 'Continue' | translate}}</span>
        <mat-icon svgIcon="pq:arrow_right"></mat-icon>
    </button>
    <div class="notice-container">
        <!-- <a class="notice-link" routerLink="/property">
            <mat-icon svgIcon="pq:input_password_protection"></mat-icon>
            <span>{{'I Don\'t Have Code' | translate}}</span>
        </a> -->
        <mat-divider vertical></mat-divider>
        <a class="notice-link" href="https://payquad.com/support-centre/" target="_blank" appOpenInAppBrowser>
            <mat-icon svgIcon="pq:support_chat"></mat-icon>
            <span>{{'Contact Help' | translate}}</span>
        </a>
    </div>
</mat-card>
