import { Injectable, Optional, SkipSelf } from "@angular/core";
import { StorageService } from "./storage.service";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, combineLatest, filter, map, noop, of, switchMap, withLatestFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class LocalizationService {

  public get currentLang() {
    return this.translate.currentLang;
  }

  constructor(
    private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private http: HttpClient,
    @Optional() @SkipSelf() otherInstance: LocalizationService,
    private authService: AuthService,
  ) {
    if (otherInstance) throw 'LocaleService should have only one instance.';

    // update user's backend language
    combineLatest([this.authService.isLoggedIn, this.translate.onLangChange]).pipe(
      filter(([isLoggedIn, lang]) => isLoggedIn), // Proceed only if the user is logged in
      map(([isLoggedIn, langEvent]) => langEvent.lang === 'fr' ? 'fr' : 'en'),
      switchMap((lang) => {
        return this.setUserLocale(lang);
      })
    ).subscribe();

    this.translate.onLangChange.pipe(
      map(langEvent => langEvent.lang === 'fr' ? 'fr' : 'en'),
    ).subscribe(async lang => {
      this.storageService.setItem('language', lang);
      moment.locale(lang);  // for amAgoPipe

      this.refreshApplicationLocaleId();
    });
  }

  setLang(lang: 'en' | 'fr') {
    this.translate.use(lang);
  }

  /**
   * Renavigate to current URL, forcing all directives to re-create.
   * For date pipes
   */
  private refreshApplicationLocaleId(): Promise<any> {
    return new Promise((resolve) => {
      this.route.queryParams.pipe(
        filter(params => Object.keys(params).length > 0), // Wait until queryParams are populated
        take(1) // Only take the first emission
      ).subscribe(() => {
        const fnShouldReuseRoute = this.router.routeReuseStrategy.shouldReuseRoute;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigated = false;

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: this.route.snapshot.queryParams,
          queryParamsHandling: 'merge'
        }).catch(noop).finally(() => {
          this.router.routeReuseStrategy.shouldReuseRoute = fnShouldReuseRoute;
          resolve(null);
        });
      });
    });
  }


  private setUserLocale(locale: 'fr' | 'en'): Observable<any> {
    return this.http.post<any>(environment.apiBaseUrl + environment.apiVersion + '/change-locale', { locale: locale });
  }
}
