import {Component, OnInit} from '@angular/core';
import {CustomisationService} from "../shared/services/customisation.service";
import { Location } from '@angular/common';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {

  constructor(
    public customisation: CustomisationService,
    private location: Location
  ) { }

  goBack(): void {
    this.location.back();
  }

  ngOnInit(): void {
  }

}
