import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";
import {AccessMenuService} from "../../shared/services/access-menu.service";
import { LocalizationService } from 'src/app/shared/services/localization.service';

@Component({
  selector: 'app-sidenav-additional-services',
  templateUrl: './sidenav-additional-services.component.html',
  styleUrls: ['./sidenav-additional-services.component.scss']
})
export class SidenavAdditionalServicesComponent implements OnInit {

  @HostBinding('class.sidenav-container') sidenavContainerClass = true;
  @Input() matSidenav?: MatSidenav;

  constructor(
    public menuService: AccessMenuService,
    public locale: LocalizationService
  ) { }

  ngOnInit(): void {
  }
}
