import {Injectable} from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';
import {DataSourceService} from "../../../../shared/rest/data-source.service";
import {PaymentMethodModel} from "../../payment-method/payment-method.model";
import {BehaviorSubject, Observable} from "rxjs";
import {PaymentHistoryModel} from "../resources/payment-history.model";
import {PaymentHistoryFilterModel} from "./payment-history-filter.model";
import {map} from "rxjs/operators";
import moment from "moment";

@Injectable()
export class PaymentHistoryFilterService {

  /**
   *  <_value> is a BehaviorSubject that manages the state of filter criteria for payment history.
   *  It stores the filter criteria as a dictionary, where each key corresponds to a specific
   *  attribute of the payment history data (e.g., `status`, `amount_from`, `amount_to`, `start_date`, etc.), and
   *  the value specifies the condition that the attribute must satisfy.
   */
  protected _value = new BehaviorSubject<{[name: string]: string | number}>({});

  constructor(
    public paymentMethodDataSource: DataSourceService<PaymentMethodModel>,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) { }

  setFilter(filter: {[name: string]: string | number}) {
    //router isn't getting passed...
    // this.router.navigate(
    //   [], 
    //   {
    //     relativeTo: this.activatedRoute,
    //     queryParams: filter, 
    //   });

    this._value.next(filter);
  }

  getFilter() {
    return this._value;
  }

  isEmpty(): Observable<boolean> {
    return this._value.pipe(
      map(v => {
        return !Object.keys(v).length || Object.values(v).reduce<boolean>((acc, item) => acc && !item, true);
      })
    )
  }

  filter(data: PaymentHistoryModel[]): PaymentHistoryModel[] {
    const filter = this.getFilter().getValue();
    if(filter.search) {
      data = data.filter(
        i => i.description?.toLowerCase().includes(filter.search.toString().toLowerCase())
        || i.total_amount?.toString().includes(filter.search.toString())
        || (Number(i.total_amount) + Number(i.tenant_fee))?.toString().includes(filter.search.toString())
        || i.id?.toString().includes(filter.search.toString())
      );
    }
    if(filter.status) {
      data = data.filter(i => i.status === filter.status);
    }
    if(filter.payment_method_id) {
      data = data.filter(i => i.payment_method?.id === Number(filter.payment_method_id));
    }
    if(filter.amount_from) {
      data = data.filter(i => (i.total_amount||0) > Number(filter.amount_from));
    }
    if(filter.amount_to) {
      data = data.filter(i => (i.total_amount||0) < Number(filter.amount_to));
    }
    if(filter.start_date) {
      data = data.filter(i => moment(i.created_at).isSameOrAfter(moment(filter.start_date)));
    }
    if(filter.end_at) {
      data = data.filter(i => moment(i.created_at).isSameOrBefore(moment(filter.end_at)));
    }
    return data;
  }

  /**
   * Extract the filters from the current URL, constructing a queryParams object out of them to be passed
   * into <router.navigate>.
   */
  _extractFiltersAsObject(url: string): any {
    const queryParams: { [key: string]: string } = {};
    const urlParts = url.split('?');
    if (urlParts.length > 1) {
      const queryParts = urlParts[1].split('&');
      queryParts.forEach(part => {
        const item = part.split('=');
        queryParams[item[0]] = decodeURIComponent(item[1]);
      });
    }
    return queryParams;
  }
}
