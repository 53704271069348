<mat-toolbar>
    <div class="toolbar-container">
        <div class="burger-button-container">
            <button mat-icon-button (click)="sidenavAdditionalServices?.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <a class="logo" [routerLink]="['/dashboard']">
            <img [src]="(customizationService.config|async)?.logoUrl || 'styles/img/logo.svg'"/>
        </a>
        <div class="navigation-items">
            <div class="navigation-item" *ngFor="let item of (menuService.itemsMain|async)">
                <a
                        [routerLink]="[item.routerLink]"
                        routerLinkActive="active"
                >
                    <mat-icon
                            class="icon"
                            [svgIcon]="item.icon"
                            matBadgeSize="small"
                            matBadgeColor="accent"
                            matBadgePosition="above after"
                            [matBadgeHidden]="(item.badge|async) === 0"
                            [matBadge]="item.badge|async"
                            aria-hidden="false"
                    ></mat-icon>
                    <span class="label">{{item.label | translate}}</span>
                </a>
            </div>

            <div class="navigation-item navigation-item-messages" *ngIf="menuService.itemMessage|async; let item">
                <a
                        [routerLink]="item.routerLink"
                        routerLinkActive="active"
                >
                    <mat-icon
                            class="icon"
                            matBadgeSize="small"
                            matBadgeColor="accent"
                            matBadgePosition="above after"
                            [svgIcon]="item.icon"
                            [matBadgeHidden]="(item.badge|async) === 0"
                            [matBadge]="item.badge|async"
                            aria-hidden="false"
                    ></mat-icon>
                    <span class="label">{{item.label | translate}}</span>
                </a>
            </div>
            <div
                    class="navigation-item navigation-item-more"
                    [mat-menu-trigger-for]="toolbarMore"
                    *ngIf="(menuService.itemsSideNav|async)?.length"
            >
                <a>
                    <mat-icon class="icon" svgIcon="pq:expand_more"></mat-icon>
                    <span class="label">{{'More' | translate}}</span>
                </a>
            </div>
        </div>
        <div class="mat-primary profile-info">
            <!-- Messages Icon -->
            <a
                    [routerLink]="item.routerLink"
                    routerLinkActive="active"
                    *ngIf="menuService.itemMessage|async; let item"
            >
                <mat-icon
                        class="item profile-mail"
                        matBadgeSize="small"
                        matBadgeColor="accent"
                        matBadgePosition="above after"
                        [svgIcon]="item.icon"
                        [matBadgeHidden]="(item.badge|async) === 0"
                        [matBadge]="item.badge|async"
                        aria-hidden="false"
                ></mat-icon>
            </a>
            <!-- Language Toggle -->
            <div class="item profile-language" [matMenuTriggerFor]="toolbarLanguage">
                <mat-icon svgIcon="pq:language"></mat-icon>
                <span>{{locale.currentLang | uppercase}}</span>
            </div>
            <!-- Profile Icon -->
            <div class="item profile-avatar" [matMenuTriggerFor]="profileMenu">
                <a>
                    <img class="avatar" [src]="avatar|async"/>
                </a>
                <mat-icon svgIcon="pq:expand_more"></mat-icon>
            </div>
        </div>
        <div class="mat-primary mobile-profile-info">
            <!-- Language Toggle -->
            <!-- <div class="item profile-language" [matMenuTriggerFor]="toolbarLanguage">
                <mat-icon svgIcon="pq:language"></mat-icon>
                <span>{{currentLang | uppercase}}</span>
            </div> -->
            <!-- Profile Icon -->
            <div class="mat-primary mobile-avatar">
                <div (click)="sidenavProfile?.toggle()">
                    <img alt="User avatar" class="avatar" [src]="avatar|async"/>
                </div>
            </div>
        </div>
    </div>
</mat-toolbar>

<mat-menu #toolbarMore="matMenu" class="pq-menu pq-menu-right main-navigation-menu" xPosition="before">
    <div class="triangle"></div>
    <button
            mat-menu-item
            *ngFor="let item of (menuService.itemsSideNav|async)"
            [routerLink]="item.routerLink"
            class="nav-button"
    >
        <div class="mat-icon-container"><mat-icon [svgIcon]="item.icon"></mat-icon></div>
        <span class="nav-label">{{item.label | translate}}</span>
    </button>
</mat-menu>

<mat-menu #toolbarLanguage="matMenu" class="pq-menu pq-menu-right main-navigation-menu" xPosition="before">
    <div class="triangle"></div>
    <button mat-menu-item (click)="locale.setLang('en')">
        <div class="mat-icon-container"><mat-icon class="icon" svgIcon="pq:language"></mat-icon></div>
        <span>{{ 'English' | translate }}</span>
        <mat-icon svgIcon="pq:checkmark_circle" class="checkmark_circle" *ngIf="locale.currentLang === 'en'"></mat-icon>
    </button>
    <button mat-menu-item (click)="locale.setLang('fr')">
        <div class="mat-icon-container"><mat-icon svgIcon="pq:language"></mat-icon></div>
        <span>{{ 'French' | translate }}</span>
        <mat-icon svgIcon="pq:checkmark_circle" class="checkmark_circle" *ngIf="locale.currentLang === 'fr'"></mat-icon>
    </button>
</mat-menu>

<mat-menu #profileMenu="matMenu" class="pq-menu pq-menu-right" xPosition="before">
    <div class="triangle"></div>
    <app-sidenav-profile></app-sidenav-profile>
</mat-menu>