<main class="main-container">
    <div class="header-container">
        <div class="logo-container">
            <!--            <img src="assets/images/logo-big.png" />-->
            <a routerLink="/">
                <img [src]="(customization.config|async)?.logoUrl || 'assets/images/logo-big.png'" />
            </a>
        </div>
    </div>
    <div class="router-container">
        <router-outlet></router-outlet>
    </div>
</main>
<app-footer *ngIf="(layout | async)?.footer"></app-footer>

