export interface AppState {
  paymentSessionKey: string | null;
}

export const initialAppState: AppState = {
  paymentSessionKey: null,
};

export interface State {
  app: AppState;
}
