import { BaseRestService } from "../../../shared/rest/base-rest.service";
import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_BASE_URL, API_VERSION } from "../../../shared/shared.module";
import { PaymentScheduleModel } from "./resources/payment-schedule.model";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, shareReplay, tap, switchMap, startWith } from "rxjs/operators";
import moment from "moment";
import { PaymentHistoryService } from "../payment-history/payment-history.service";
import { IGNORE_TOASTR_ERROR } from "../../../shared/error.interceptor";
import { PaymentScheduleTransactionModel } from "./resources/payment-schedule-transaction.model";
import { PaymentDuplicateInfoModel } from "./resources/payment-duplicate-info.model";

@Injectable({
  providedIn: "root"
})
export class PaymentScheduleService extends BaseRestService<PaymentScheduleModel, PaymentScheduleModel, PaymentScheduleModel, PaymentScheduleModel, PaymentScheduleModel> {
  protected resourceName = 'payments/payment-schedules';

  nextPaymentObservable(data: Observable<PaymentScheduleModel[]>): Observable<PaymentScheduleModel | undefined> {
    return data.pipe(
      map(d => d.filter(i => moment().diff(moment(i.next_at), 'days') <= 0 && !i.is_suspended && !i.paused)),
      map(d => d.sort((a, b) => moment(a.next_at).diff(moment(b.next_at))).shift())
    )
  }

  constructor(
    http: HttpClient,
    @Inject(API_BASE_URL) baseUrl: string,
    @Inject(API_VERSION) version: string,
    private paymentHistoryService: PaymentHistoryService,
  ) {
    super(http, baseUrl, version);
    this.dataChanged.subscribe(() => this.paymentHistoryService.dataChanged.emit(true))
  }

  create(item: any): Observable<PaymentScheduleModel> {
    return super.create(item);
  }

  getPotentialDuplicate(): Observable<PaymentDuplicateInfoModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    }
    return this.dataChanged.pipe(
      startWith(true), //so it fires at least once
      switchMap(() => this.http.get<any>(this.baseUrl + this.version + `/payments/potential-dupe`, httpOptions)),
    );

  }

  generatePaymentSessionKey(): string {
    const currentDatetime = new Date().toISOString();
    return currentDatetime;
  }

}
