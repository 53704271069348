import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfirmationWindowDataModel} from "./confirmation-window-data.model";

@Component({
  selector: 'app-confirmation-window',
  templateUrl: './confirmation-window.component.html',
  styleUrls: ['./confirmation-window.component.scss']
})
export class ConfirmationWindowComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmationWindowDataModel,
    @Optional() public matDialogRef: MatDialogRef<ConfirmationWindowComponent>
  ) { }

  ngOnInit(): void {
  }

}
