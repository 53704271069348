import {BaseRestService} from "../../../shared/rest/base-rest.service";
import {PaymentMethodModel} from "./payment-method.model";
import {Inject, Injectable} from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import {API_BASE_URL, API_VERSION} from "../../../shared/shared.module";
import {BehaviorSubject, Observable, of} from "rxjs";
import {PaymentMethodType} from "./payment-method.type";
import {filter, map, shareReplay, switchMap, tap} from "rxjs/operators";

@Injectable()
export class PaymentMethodService extends BaseRestService<PaymentMethodModel, PaymentMethodModel, PaymentMethodModel, PaymentMethodModel, PaymentMethodModel>{
  protected resourceName = 'payment-methods';

  labels: Observable<{value: PaymentMethodType, label: string}[]> = of([
    {value: PaymentMethodType.visa,         label: 'Visa'},
    {value: PaymentMethodType.amex,         label: 'Amex '},
    {value: PaymentMethodType.discover,     label: 'Discover '},
    {value: PaymentMethodType.mastercard,   label: 'Mastercard '},
    {value: PaymentMethodType.debit_visa,   label: 'Debit Visa '},
    {value: PaymentMethodType.direct_debit, label: 'Direct Debit '},
    {value: PaymentMethodType.cash,         label: 'Cash '},
    {value: PaymentMethodType.check,        label: 'Check '},
    {value: PaymentMethodType.etransfer,    label: 'ETransfer '},
    {value: PaymentMethodType["n/a"],       label: 'N/A '},
  ]);

  icons: Observable<{type: PaymentMethodType, svgIcon: string}[]> = of([
    {type: PaymentMethodType.visa,         svgIcon: 'pq:credit_card'},
    {type: PaymentMethodType.amex,         svgIcon: 'pq:credit_card'},
    {type: PaymentMethodType.discover,     svgIcon: 'pq:credit_card'},
    {type: PaymentMethodType.mastercard,   svgIcon: 'pq:credit_card'},
    {type: PaymentMethodType.debit_visa,   svgIcon: 'pq:credit_card'},
    {type: PaymentMethodType.direct_debit, svgIcon: 'pq:bank_shield_protection_checkmark'},
    {type: PaymentMethodType.cash,         svgIcon: 'pq:bank_shield_protection_checkmark'},
    {type: PaymentMethodType.check,        svgIcon: 'pq:bank_shield_protection_checkmark'},
    {type: PaymentMethodType.etransfer,    svgIcon: 'pq:bank_shield_protection_checkmark'},
  ]);

  banks: Observable<{value: string, label: string}[]> = of([
    {value: '001', label: 'Bank of Montreal'},
    {value: '002', label: 'ScotiaBank'},
    {value: '003', label: 'RBC'},
    {value: '004', label: 'TD Canada Trust'},
    {value: '006', label: 'National Bank of Canada'},
    {value: '010', label: 'CIBC'},
    {value: '010', label: 'Simplii'},
    {value: '320', label: 'President’s Choice Bank'},
    {value: '016', label: 'HSBC'},
    {value: '177', label: 'Bank of Canada'},
    {value: '260', label: 'Citibank Canada'},
    {value: '309', label: 'Citizens Bank of Canada'},
    {value: '614', label: 'ING Bank of Canada '},
    {value: '030', label: 'Canadian Western Bank'},
    {value: '039', label: 'Laurentian Bank of Canada'},
    {value: '219', label: 'ATB Financial'},
    {value: '241', label: 'Bank of America Canada'},
    {value: '260', label: 'Citibank Canada'},
    {value: '270', label: 'JPMorgan Chase Bank, N.A. (Toronto Branch)'},
    {value: '290', label: 'UBS Bank'},
    {value: '308', label: 'Bank of China'},
    {value: '309', label: 'Citizens Bank of Canada (Canada)'},
    {value: '338', label: 'Canadian Tire Bank'},
    {value: '340', label: 'ICICI Bank Canada'},
    {value: '540', label: 'Manulife Bank'},
    {value: '614', label: 'ING Direct Canada'},
    {value: '815', label: 'Caisses Desjardins du Québec'},
    {value: '829', label: 'Caisses populaires Desjardins de l’Ontario'},
    {value: 'other', label: 'Other Bank'},
  ]);

  availableMethods = this.http.get<{
    message: string,
    data: {
      methods: Array<{value: string, name: string, tenant_fee: string}>,
      credit_cards: Array<string>
    }
  }>(
    `${this.baseUrl}${this.version}/${this.resourceName}/available`
  ).pipe(
    tap(() => this.availableMethodsLoading.next(false)),
    shareReplay()
  );
  availableMethodsLoading = new BehaviorSubject(true);

  availableCreditCards = this.availableMethods.pipe(
    map(d => d.data.credit_cards)
  );

  constructor(
    http: HttpClient,
    @Inject(API_BASE_URL) baseUrl: string,
    @Inject(API_VERSION) version: string
  ) {
    super(http, baseUrl, version);
  }

  getMethodLabel(type: string): Observable<string|undefined> {
    return this.labels.pipe(
      switchMap(m => of(m.find(i => i.value === type)?.label)),
      filter(t => !!t)
    );
  }

  getMethodIcon(type: string): Observable<string|undefined> {
    return this.icons.pipe(
      switchMap(icons => of(icons.find(i => i.type === type)?.svgIcon))
    );
  }

  getMethodAvailable(method: string): Observable<{value: string, name: string, tenant_fee: string} | undefined> {
    return this.availableMethods.pipe(
      map(d => d.data.methods),
      switchMap(m => of(m.find(i => i.value === method)))
    );
  }

  calcConvenienceFee(totalAmount: number, paymentMethodId: number)
    : Observable<HttpResponse<{fee_percent: number, tenant_convenience_fees: number}>>
  {
    return this.http.get<{fee_percent: number, tenant_convenience_fees: number}>(
      this.baseUrl + this.version + '/payment-methods/convenience-fees',
      {
        observe: "response",
        params: new HttpParams({fromObject: {total_amount: totalAmount.toFixed(2), payment_method_id: paymentMethodId}})
      }
    )
  }
}
