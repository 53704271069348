import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginFormComponent} from './login-form/login-form.component';
import {SharedModule} from "../shared/shared.module";
import {RegisterFormComponent} from './register-form/register-form.component';
import {DownloadAppComponent} from './download-app/download-app.component';
import {CodeFormComponent} from './code-form/code-form.component';
import {ResetComponent} from './reset/reset.component';
import {EmailComponent} from './email/email.component';
import {PropertyComponent} from './property/property.component';
import { PropertyNotFoundDialog } from './property/property-not-found/property-not-found.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';


@NgModule({
  declarations: [
    LoginFormComponent,
    RegisterFormComponent,
    DownloadAppComponent,
    CodeFormComponent,
    ResetComponent,
    EmailComponent,
    PropertyComponent,
    PropertyNotFoundDialog,
    ResetPasswordComponent,
  ],
  exports: [
    RegisterFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
  ]
})
export class LoginModule { }
