import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {
  PaymentMethodCreateComponent
} from "../../../tenant/payment/payment-method/payment-method-create/payment-method-create.component";
import { NewPaymentMethodService } from './new-payment-method.service';
import {AbstractControl} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-add-new-method-button',
  templateUrl: './add-new-method-button.component.html',
  styleUrls: ['./add-new-method-button.component.scss']
})
export class AddNewMethodButtonComponent implements OnInit, OnDestroy {
  @Input() formControlSetup?: AbstractControl;
  private subscriptions: Subscription[] = []

  constructor(
    public paymentMethodService: NewPaymentMethodService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s ? s.unsubscribe() : null)
  }

  openDialog() {
    const dialog = this.dialog.open(PaymentMethodCreateComponent);
    this.subscriptions.push(
      dialog.afterClosed().subscribe(
        (payment_method_id) => {
          this.formControlSetup?.setValue(payment_method_id);
        }
      )
    )
    this.subscriptions.push(
      this.paymentMethodService.newPaymentMethod.subscribe(
        (payment_method) => {
          this.formControlSetup?.setValue(payment_method?.id);
        }
      )
    )
  }

}
