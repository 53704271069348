import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { Observable, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router: Router = inject(Router);
  const authService: AuthService = inject(AuthService);
  const dialog: MatDialog = inject(MatDialog);

  return authService.isLoggedIn.pipe(
    tap(isLoggedIn => {
      if (!isLoggedIn) {
        console.log('not logged in');
        dialog.closeAll();
      }
    }),
    switchMap(isLoggedIn => {
      if (isLoggedIn) {
        return of(isLoggedIn);
      }
      return of(router.createUrlTree(['/login'], { //if not logged in, redirect to login
        queryParams: { returnUrl: state.url } //return to original url after login
      }));
    })
  )
}