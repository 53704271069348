import {InjectionToken, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadableDirective} from "./skeleton/loadable.directive";
import {LoadableHostDirective} from './loadable-host.directive';
import {MatGridListModule} from "@angular/material/grid-list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatBadgeModule} from "@angular/material/badge";
import {MatMenuModule} from "@angular/material/menu";
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {MatCardModule} from "@angular/material/card";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDividerModule} from "@angular/material/divider";
import {ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgxPrintModule} from "ngx-print";
import {MatRippleModule} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {AddNewMethodButtonComponent} from './components/add-new-method-button/add-new-method-button.component';
import {ConfirmationWindowComponent} from './components/confirmation-window/confirmation-window.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {LightboxModule} from "ng-gallery/lightbox";
import {MatTabsModule} from "@angular/material/tabs";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TemplateTypedDirective} from './directives/template-typed.directive';
import { OpenInAppBrowserDirective } from './directives/open-inapp-browser.directive';
import { NewPaymentMethodService } from './components/add-new-method-button/new-payment-method.service';
import { EllipsesLoaderComponent } from './components/ellipses-loader/ellipses-loader.component';
import { LoginAsNoticeComponent } from './login-as-notice/login-as-notice.component';

export const API_BASE_URL = new InjectionToken<string>('BaseUrl');
export const API_VERSION = new InjectionToken<string>('Version');
export const API_BASE = new InjectionToken<string>('API_BASE');
export const OPENWEATHER_API_KEY = new InjectionToken<string>('OpenWeatherApiKey');

@NgModule({
  declarations: [
    LoadableDirective,
    LoadableHostDirective,
    AddNewMethodButtonComponent,
    ConfirmationWindowComponent,
    TemplateTypedDirective,
    OpenInAppBrowserDirective,
    EllipsesLoaderComponent,
    LoginAsNoticeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    TranslateModule,
    NgSelectModule,
    NgxPrintModule,

    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDividerModule,
    MatGridListModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatMenuModule,
    MatDialogModule,
    MatInputModule,
    MatMomentDateModule,
    MatCardModule,
    MatRippleModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    LightboxModule.withConfig({
      panelClass: 'pqLightbox'
    }),
  ],
  exports: [

    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    TranslateModule,
    NgSelectModule,
    NgxPrintModule,

    LoadableDirective,
    LoadableHostDirective,

    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDividerModule,
    MatGridListModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatMenuModule,
    MatDialogModule,
    MatInputModule,
    MatMomentDateModule,
    MatCardModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatTabsModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    LightboxModule,
    AddNewMethodButtonComponent,
    TemplateTypedDirective,
    OpenInAppBrowserDirective,
    EllipsesLoaderComponent,
    LoginAsNoticeComponent,
  ],
  providers: [
    NewPaymentMethodService,
  ]
})
export class SharedModule { }
