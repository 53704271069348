
import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'property-not-found-dialog',
    templateUrl: './property-not-found-dialog.html',
    styleUrls: ['./property-not-found-dialog.component.scss'],
  })
  export class PropertyNotFoundDialog {
    constructor(
      private translate: TranslateService,
    ) {
    }
  }
  