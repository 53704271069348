import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, HostListener} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {EmailValidator, PqValidators} from "../../shared/validators/pq.validators";
import {BehaviorSubject, Observable} from "rxjs";
import {AuthService} from "../../shared/services/auth.service";
import {RegistrationService} from "../registration.service";
import {ActivatedRoute, Router, UrlTree} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {CustomisationService} from "../../shared/services/customisation.service";
import {take} from "rxjs/operators";
import {UserService} from "../../shared/services/user.service";
import {ComponentPendingChanges} from "../../shared/interfaces/component-pending-changes.interface";
import {ConfirmationWindowComponent} from "../../shared/components/confirmation-window/confirmation-window.component";
import {ConfirmationWindowDataModel} from "../../shared/components/confirmation-window/confirmation-window-data.model";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit, OnDestroy, ComponentPendingChanges  {

  preventActive: boolean;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.preventActive) {
      return this.dialog.open(
        ConfirmationWindowComponent,
        {
          data: new ConfirmationWindowDataModel(
            this.translate.instant('Do you really want to leave the page?'),
            this.translate.instant('Your changes may not be saved.'),
            this.translate.instant('Leave'),
            'warn',
            this.translate.instant('Cancel'),
            'primary',
          )
        }
      ).afterClosed();
    }
    return true;
  }


  // Template setting
  @Input() templateTitle          = true;
  @Input() templateDownloadApp    = true;
  @Input() templateLoginLink      = true;
  @Input() templateLoginCodeLink  = true;

  @Input() suppressMatCard        = false;

  @Output() submit = new EventEmitter();
  @Input()  submitButtonTemplate?: any;

  public formGroup: UntypedFormGroup;

  showPassword = new BehaviorSubject(false);
  showPasswordConfirmation = new BehaviorSubject(false);
  
  showLoginWithCodeText = new BehaviorSubject<boolean>(true);

  public sendActiveRequest = new BehaviorSubject<boolean>(false);

  constructor(
    public customization: CustomisationService,
    private authService: AuthService,
    private userService: UserService,
    private registrationService: RegistrationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private emailAsyncValidator: EmailValidator,
    private notif: ToastrService,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {
    const data  = this.activatedRoute.snapshot.data;
    this.suppressMatCard        = data.suppressMatCard || false;
    this.templateTitle          = data.templateTitle !== undefined          ? data.templateTitle          : this.templateTitle;
    this.templateDownloadApp    = data.templateDownloadApp !== undefined    ? data.templateDownloadApp    : this.templateDownloadApp;
    this.templateLoginLink      = data.templateLoginLink !== undefined      ? data.templateLoginLink      : this.templateLoginLink;
    this.templateLoginCodeLink  = data.templateLoginCodeLink !== undefined  ? data.templateLoginCodeLink  : this.templateLoginCodeLink;

    const serviceData = this.registrationService.registrationFormData.getValue();
    this.showLoginWithCodeText.next(!serviceData.security_code);
    this.formGroup = new UntypedFormGroup(
      {
        first_name:   new UntypedFormControl(
          serviceData.invite_code || serviceData.security_code ? {value: serviceData.first_name, disabled: true} : '',
          [Validators.required, Validators.maxLength(255)]
        ),
        last_name:    new UntypedFormControl(
          serviceData.invite_code || serviceData.security_code ? {value: serviceData.last_name, disabled: true} : '',
          [Validators.required, Validators.maxLength(255)]
        ),
        email:        new UntypedFormControl(
          serviceData.invite_code ? {value: serviceData.email, disabled: true} : '',
          {
            validators: [Validators.required, Validators.email],
            asyncValidators:[emailAsyncValidator.registration()],
            updateOn: "blur"
          },
        ),
        phone_number: new UntypedFormControl('', [Validators.required, Validators.pattern('[- +()0-9]{10,}') ]),
        terms:        new UntypedFormControl(false, [Validators.requiredTrue]),
        userType:     new UntypedFormControl('tenant'),
        password:     new UntypedFormControl(
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.pattern('.*[0-9]+.*'), //contains 1 number
          ]
        ),
        password_confirmation: new UntypedFormControl(
          '',
          [
            Validators.required,
            Validators.minLength(6),
            // Validators.pattern('(?=.*[0-9])'),
            PqValidators.notSame('password'), 
          ]
        ),
      },
    );
    this.formGroup.get('password')?.valueChanges.subscribe(() => {
      this.formGroup.get('password_confirmation')?.updateValueAndValidity();
    });

    console.log(this.registrationService.registrationFormData.getValue());

    //if data already filled about unit/property, warn before reload erases info.
    this.preventActive = !!this.registrationService.registrationFormData.getValue().unit;

    if(this.preventActive){
      window.addEventListener('beforeunload', this.preventReload);
    } else {
      this.router.navigate(['/login']);
    }
  }

  preventReload(event: BeforeUnloadEvent): string {
    event.preventDefault();
    event.returnValue = '';
    return '';
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if(this.formGroup.valid) {
      console.log(this.formGroup.value);
      this.registrationService.setRegistrationFormData(this.formGroup.value);
      this.sendActiveRequest.next(true);
      console.log('registration', 'data', this.registrationService.registrationFormData.getValue());

      this.registrationService.send().subscribe({
        next: r => {
          this.registrationService.clearRegistrationFormData();
          this.preventActive = false; //allow to leave page
          const body: any = r.body;
          const token = body.data?.access_token || body?.access_token;
          console.log('+++++registration', 'token', token);
          if(token) {
            this.authService.setToken(token);
            this.userService.callMeUpdate();
            this.userService.me().pipe(take(1)).subscribe(
              u => {
                window.rudderanalytics.identify(u.id, {
                  email: u.email,
                  first_name: u.first_name,
                  last_name: u.last_name,
                  phone: u.phone_number
                });
                if (u.is_onboarding_complete) {
                  this.router.navigate(['/dashboard']);
                } else {
                  this.router.navigate(['/onboarding/payments']);
                }
              }
            );
          }else if(body.errors) {
            if(body.errors[0] === 'Your Email and/or Password are incorrect or you might not be Approved yet.') {
              this.router.navigate(['/onboarding/pending']);
            }else{
              console.log("Error1", body.errors[0]);
              this.notif.error(body.errors[0], this.translate.instant('Error'));
              this.router.navigate(['/login']);
            }
          } else {
            console.log("Error2");
            this.notif.error('', this.translate.instant('Error'));
            this.router.navigate(['/login']);
          }
          this.sendActiveRequest.next(false);
        },
        error: () => {
          console.log("Error3");
          this.sendActiveRequest.next(false);
        }
      })

      this.submit.emit('SubmitResultMessage');
    } else {
      this.formGroup.markAllAsTouched();
    }
    console.log(this.formGroup);
    return false;
  }

  toggleShowPassword() {
    this.showPassword.next(!this.showPassword.getValue());
  }

  toggleShowPasswordConfirmation() {
    this.showPasswordConfirmation.next(!this.showPasswordConfirmation.getValue());
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.preventReload);
    this.notif.clear();
  }



}
