import {AbstractControl, AsyncValidator, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {Injectable} from "@angular/core";
import {combineLatest, Observable, of} from "rxjs";
import {RegistrationService} from "../../login/registration.service";
import {catchError, first, map, switchMap, tap} from "rxjs/operators";
import moment from "moment";
import {PaymentMethodService} from "../../tenant/payment/payment-method/payment-method.service";
import {PropertyModel} from "../../shared/models/property.model";

export class PqValidators extends Validators {
  static notSame(repeatControlName: string): ValidatorFn {
    return notSameValidator(repeatControlName);
  }

  static cardNumber(control: AbstractControl): ValidationErrors | null {
    let value = control.value;
    if (/[^0-9-\s]+/.test(value)) return {cardNumber: true};

    // The Luhn Algorithm. It's so pretty.
    let nCheck = 0, bEven = false;
    value      = value.replace(/\D/g, "");

    for (let n = value.length - 1; n >= 0; n--) {
      let cDigit = value.charAt(n),
          nDigit = parseInt(cDigit, 10);

      if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

      nCheck += nDigit;
      bEven = !bEven;
    }

    return (nCheck % 10) === 0 ? null : {cardNumber: true};
  }

  static cardExpire(monthControlName: string, yearControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if(control.root.get(monthControlName)?.value && control.root.get(yearControlName)?.value) {
        console.log(
          `${control.root.get(monthControlName)?.value}-${control.root.get(yearControlName)?.value}`,
          moment(new Date()).format('YYYY-MM')
          )
        return `${control.root.get(yearControlName)?.value}-${control.root.get(monthControlName)?.value}` > moment(new Date()).format('YYYY-MM')
               ? null
               : {cardExpire: true};
      }
      return null;
    }
  }

  static requiredIf(condition: (root: AbstractControl) => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return condition(control.root) ? this.required(control) : null;
    }
  }

  static validPostalCode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const postalCodeRegex = /^(\d{5}(-\d{4})?|[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d)(\s*)$/i;
      
      if (control.value && !postalCodeRegex.test(control.value)) {
        return { invalidPostalCode: true };
      }
  
      return null;
    };
  }
}

export function notSameValidator(repeatControlName: string): ValidatorFn {
  return (control: AbstractControl):  ValidationErrors | null => {
    return control.root.get(repeatControlName)?.value === control.value ? null : {notSame: true};
  };
}

@Injectable()
export class CreditCardValidator {
  constructor(private paymentMethodService: PaymentMethodService) {}
  cardSupport(currentType: Observable<string>): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return combineLatest([
        currentType,
        this.paymentMethodService.availableCreditCards
      ]).pipe(
        tap(([curr, av]) => console.log(curr, av)),
        switchMap(([curr, av]) => of(av.some(i => i === curr) ? null : {cardSupport: true})),
        first()
      );
    }
  }
}

@Injectable()
export class EmailValidator {
  constructor(private service: RegistrationService) {}
  registration(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.service.validateEmail(control.value).pipe(
        catchError((err, caught) => {
          console.log(err, caught);
          if (err.status === 422 && Object.values(err.error?.errors).length) {
            const errors = Object.entries(err.error?.errors);
            if(errors[0] && Array.isArray(errors[0])) {
              // show first error
              const e = errors.reduce(
                (acc: any, i) => {
                  if (Array.isArray(i[1])) {
                    acc[i[0]] = i[1][0];
                  }
                  return acc;
                },
                {}
              );
              return of(Object.entries(e).length ? e : null);
            }
          }
          return of(null);
        }),
        tap(r => console.log(r)),
        map(r => r.status && r.status === 200 ? null : r),
      );
    };
  }
}