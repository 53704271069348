<mat-card class="form-mat-card">
    <div class="title">{{'Reset Password' | translate}}</div>
    <form class="pq-form" [formGroup]="formGroup">
        <label>{{'Email' | translate}}</label>
        <mat-form-field class="full-width-field">
            <input matInput placeholder="example@domain.com" formControlName="email">
            <mat-error>
                <span *ngIf="formGroup.controls['email']?.errors?.required">{{'Email is required field' | translate}}</span>
                <!--                <span *ngIf="formGroup.controls['email']?.errors?.email">{{'Not valid email' | translate}}</span>-->
                <span *ngIf="formGroup.controls['email']?.errors?.email?.length" [innerHtml]="formGroup.controls['email'].errors?.email"></span>
            </mat-error>
        </mat-form-field>

        <label>{{'Create Password' | translate}}</label>
        <mat-form-field class="full-width-field mat-form-field-button-suffix">
            <input
                    matInput
                    formControlName="password"
                    [type]="(showPassword | async) ? 'text' : 'password'"
                    [placeholder]="'Password, at least 6 characters' | translate"
            />
            <button matSuffix mat-icon-button tabindex="-1" (click)="toggleShowPassword()">
                <mat-icon svgIcon="pq:eye"></mat-icon>
            </button>
            <mat-error>
                <span *ngIf="formGroup.controls['password']?.errors?.required">{{'Password is required field' | translate}}</span>
                <span *ngIf="formGroup.controls['password']?.errors?.minlength">{{'Min length of 6 characters' | translate}}</span>
            </mat-error>
        </mat-form-field>

        <label>{{'Confirm Password' | translate}}</label>
        <mat-form-field class="full-width-field mat-form-field-button-suffix">
            <input
                    matInput
                    formControlName="password_confirmation"
                    [type]="(showPasswordConfirmation | async) ? 'text' : 'password'"
                    [placeholder]="'Repeat password' | translate"
            />
            <button matSuffix mat-icon-button tabindex="-1" (click)="toggleShowPasswordConfirmation()">
                <mat-icon svgIcon="pq:eye"></mat-icon>
            </button>
            <mat-error>
                <span *ngIf="formGroup.controls['password_confirmation']?.errors?.required">{{'Password is required field' | translate}}</span>
                <span *ngIf="formGroup.controls['password_confirmation']?.errors?.minlength">{{'Min length of 6 characters' | translate}}</span>
                <span *ngIf="formGroup.controls['password_confirmation']?.errors?.notSame">{{'Password mismatch' | translate}}</span>
            </mat-error>
        </mat-form-field>

        <div class="one-row one-row-stop-break one-row-small-gap body4">
            <mat-checkbox color="primary" formControlName="terms">
                <span class="body4" style="line-height: 19px">
                    {{'I have read and agree to the ' | translate}}
                    <a class="accent-primary-1" href="/terms-of-service" target="_blank">{{'Terms&nbsp;of&nbsp;Service' | translate}}</a>
                    {{'and' | translate}}
                    <a class="accent-primary-1" href="/privacy-policy" target="_blank">{{'Privacy&nbsp;Policy' | translate}}</a>
                </span>
            </mat-checkbox>
        </div>
        
        <mat-error *ngIf="formGroup.controls['terms'].touched && formGroup.controls['terms']?.errors?.required">
            <span class="body4">
                {{'Need to accept Terms&nbsp;of&nbsp;Service' | translate}}
                {{'and' | translate}}
                {{'Privacy&nbsp;Policy' | translate}}
            </span>
        </mat-error>
        <button
                mat-flat-button
                color="primary"
                class="full-width-field send-button"
                (click)="send()"
                [disabled]="disableButton|async"
        >{{'Reset Password' | translate}}</button>
<!--        <div class="notice-container">-->
<!--            {{'Already have an account?' | translate}} <a class="notice-link" routerLink="/login"> {{'Login' | translate}}</a>-->
<!--        </div>-->
    </form>
</mat-card>
